import {
  Breadcrumbs,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import Title from "../../../Components/Title";
import {
  getLastParameter,
  getToken,
  requestErrorHandler,
} from "../../../Utils/Common";
import EnvSettings from "../../../Utils/EnvSettings";
import { useStyles } from "../../../Utils/Styles";
import DeviceProfileSection from "./SubscriptionSections/DeviceProfileSection";
import DeviceSection from "./SubscriptionSections/DeviceSection";
import OrderSection from "./SubscriptionSections/OrderSection";
import ProductLineSection from "./SubscriptionSections/ProductLineSection";
import ResupplyOrderSection from "./SubscriptionSections/ResupplyOrderSection";
import ShipmentsSection from "./SubscriptionSections/ShipmentsSections";
import StockItemSection from "./SubscriptionSections/StockItemSection";
import UserSection from "./SubscriptionSections/UserSection";
const server = EnvSettings.server;

export default function EditEmailList(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [emaillist, setEmailList] = useState();
  const list_id = getLastParameter();
  const dispatch = useDispatch();
  const [unedited, setUnedited] = useState();
  const [open, setOpen] = useState(false);
  const [resetKey, setResetKey] = useState("dsfafasd");

  const getEmailList = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        list_id: list_id,
      }),
    };
    fetch(server + "/get_email_lists", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setEmailList(result.emaillist);
          setUnedited(result.emaillist);
        }
        if (result.error && result.message === "Invalid user") {
          window.location.replace("/cpanel/emaillists");
        }
      });
  };

  const submitList = (remove) => {
    if (JSON.stringify(emaillist) === JSON.stringify(unedited) && !remove) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        list: emaillist,
        remove_list: remove,
      }),
    };
    fetch(server + "/edit_email_list", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          if (remove) {
            window.location.replace("/cpanel/emaillists");
            return;
          }
          window.location.reload();
        }
      });
  };

  useEffect(getEmailList, []);

  return (
    <Grid container spacing={3}>
      <Dialog open={open}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              submitList(true);
            }}
          >
            {t("yes")}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
            }}
          >
            {t("no")}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Breadcrumbs>
                <Link color="inherit" href="/cpanel/emaillists">
                  <Typography>{t("emailLists.header")}</Typography>
                </Link>
                <Typography>{t("emailLists.editEmailList")}</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
              <Title>{t("emailLists.editEmailList")}</Title>
            </Grid>
            <Grid item xs={12}>
              {emaillist ? (
                <TextField
                  variant="outlined"
                  label={t("emailLists.name")}
                  value={emaillist.name}
                  style={{ width: 300 }}
                  onChange={(e) => {
                    setEmailList({ ...emaillist, name: e.target.value });
                  }}
                ></TextField>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Grid item>
                {emaillist ? (
                  <FormControlLabel
                    defaultChecked={!!emaillist.allOrderAlerts}
                    control={
                      <Checkbox
                        color="primary"
                        onChange={(e, v) => {
                          setEmailList({
                            ...emaillist,
                            allOrderAlerts: v ? 1 : 0,
                          });
                        }}
                        defaultChecked={!!emaillist.allOrderAlerts}
                      />
                    }
                    label={t("emailLists.subscribeAllOrderAlerts")}
                  />
                ) : null}
              </Grid>
              <Grid item>
                {emaillist ? (
                  <FormControlLabel
                    defaultChecked={!!emaillist.allStockAlerts}
                    control={
                      <Checkbox
                        color="primary"
                        onChange={(e, v) => {
                          setEmailList({
                            ...emaillist,
                            allStockAlerts: v ? 1 : 0,
                          });
                        }}
                        defaultChecked={!!emaillist.allStockAlerts}
                      />
                    }
                    label={t("emailLists.subscribeAllStockAlerts")}
                  />
                ) : null}
              </Grid>
            </Grid>
            <UserSection
              unedited={unedited}
              emaillist={emaillist}
              setEmailList={setEmailList}
            />
            <DeviceSection
              unedited={unedited}
              emaillist={emaillist}
              setEmailList={setEmailList}
            />
            <ProductLineSection
              unedited={unedited}
              emaillist={emaillist}
              setEmailList={setEmailList}
            />
            <DeviceProfileSection
              unedited={unedited}
              emaillist={emaillist}
              setEmailList={setEmailList}
            />
            <OrderSection
              unedited={unedited}
              emaillist={emaillist}
              setEmailList={setEmailList}
            />
            <ResupplyOrderSection
              unedited={unedited}
              emaillist={emaillist}
              setEmailList={setEmailList}
            />
            <ShipmentsSection
              unedited={unedited}
              emaillist={emaillist}
              setEmailList={setEmailList}
            />
            <StockItemSection
              unedited={unedited}
              emaillist={emaillist}
              setEmailList={setEmailList}
            />
            <Grid item xs={12}>
              {emaillist ? (
                <>
                  <Button
                    disabled={
                      JSON.stringify(unedited) === JSON.stringify(emaillist)
                    }
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      submitList();
                    }}
                  >
                    {t("submit")}
                  </Button>
                  <Button
                    style={{ marginLeft: 10 }}
                    disabled={
                      JSON.stringify(unedited) === JSON.stringify(emaillist)
                    }
                    variant="contained"
                    onClick={() => {
                      setEmailList(unedited);
                    }}
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    style={{ marginLeft: 10 }}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    {t("delete")}
                  </Button>
                </>
              ) : null}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
