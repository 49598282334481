import { InputAdornment, TextField, Typography } from "@material-ui/core";
import React from "react";

export default function EditableTypography({
  edit,
  prefix,
  name,
  onChange,
  multiline,
  style,
  children,
}) {
  return edit ? (
    <TextField
      value={children}
      prefix={prefix}
      fullWidth
      onChange={onChange}
      label={name}
      multiline={multiline}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{prefix}</InputAdornment>
        ),
      }}
    ></TextField>
  ) : (
    <Typography style={{ whiteSpace: "pre-wrap" }}>
      {prefix}
      {children}
    </Typography>
  );
}
