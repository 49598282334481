import { IconButton, MenuItem } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Clear from "@material-ui/icons/Clear";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Title from "../../../../Components/Title";
import UserSearchAutocomplete from "../../../../Components/UserSearchAutocomplete";
import EnvSettings from "../../../../Utils/EnvSettings";

const server = EnvSettings.server;

export default function UserSection({ unedited, emaillist, setEmailList }) {
  unedited = unedited || emaillist;
  const { t } = useTranslation();
  const [resetKey, setResetKey] = useState("sdfasfsda");

  return (
    <Grid item sm={3} xs={12}>
      <Title>{t("emailLists.users")}</Title>
      {unedited &&
        unedited.EmailListUsers &&
        unedited.EmailListUsers.map((u, i) =>
          u ? (
            <MenuItem
              key={i}
              style={
                !emaillist.EmailListUsers.find(
                  (uu) => uu.User.emailAddress === u.User.emailAddress
                )
                  ? { textDecoration: "line-through" }
                  : {}
              }
            >
              {u.User.emailAddress}
              <IconButton
                style={{ position: "absolute", right: 0 }}
                onClick={() => {
                  setEmailList({
                    ...emaillist,
                    EmailListUsers: emaillist.EmailListUsers.filter(
                      (f) => f.User.id !== u.User.id
                    ),
                  });
                }}
              >
                <Clear />
              </IconButton>
            </MenuItem>
          ) : null
        )}
      {emaillist &&
        unedited &&
        emaillist.EmailListUsers &&
        emaillist.EmailListUsers.filter(
          (u) => !unedited.EmailListUsers.find((f) => f.User.id === u.User.id)
        ).map((u, i) => (
          <MenuItem
            key={i}
            style={{
              backgroundColor: "rgb(177 255 184)",
            }}
          >
            {u.User.emailAddress}
            <IconButton
              style={{ position: "absolute", right: 0 }}
              onClick={() => {
                setEmailList({
                  ...emaillist,
                  EmailListUsers: emaillist.EmailListUsers.filter(
                    (f) => f.User.id !== u.User.id
                  ),
                });
              }}
            >
              <Clear />
            </IconButton>
          </MenuItem>
        ))}
      <UserSearchAutocomplete
        style={{ marginTop: 5 }}
        email={true}
        key={resetKey}
        label={t("emailLists.searchUser")}
        onChange={(e, v) => {
          if (!v || !v.emailAddress) {
            return;
          }
          setEmailList({
            ...emaillist,
            EmailListUsers: [...(emaillist.EmailListUsers || []), { User: v }],
          });
          setResetKey(new Date().toISOString());
        }}
      />
    </Grid>
  );
}
