import { Box, Container, CssBaseline } from "@material-ui/core";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import Copyright from "../Components/Copyright";
import Sidebar from "../Components/Sidebar";
import Topbar from "../Components/Topbar";
import { USER_GROUPS, checkRequiredPermissions, getToken } from "./Common";
import { useStyles } from "./Styles";

function verifyAdmin() {
  if (getToken()) {
    if (checkRequiredPermissions([USER_GROUPS.ADMIN])) {
      return true;
    }
  }
  return false;
}
// handle the private routes
function AdminRoute({
  superuser,
  redirectionTo,
  component: Component,
  ...rest
}) {
  const classes = useStyles();
  const redirection = window.location.pathname;
  if (redirectionTo) {
    return (
      <Route
        render={() => (
          <Redirect to={{ pathname: redirectionTo(redirection) }} />
        )}
      />
    );
  }
  return (
    <Route
      render={() =>
        superuser && !checkRequiredPermissions([USER_GROUPS.SUPER_USER]) ? (
          <Redirect to={{ pathname: "/cpanel" }} />
        ) : verifyAdmin() ? (
          <React.Fragment>
            <CssBaseline />
            <Topbar props={{ ...rest }} />
            <Sidebar />
            <main className={classes.content}>
              <div className={classes.appBarSpacer} />
              <Container maxWidth={false} className={classes.container}>
                <Component {...rest} />
                <Box pt={4}>
                  <Copyright />
                </Box>
              </Container>
            </main>
          </React.Fragment>
        ) : (
          <Redirect to={{ pathname: "/admin_login" + redirection }} />
        )
      }
    />
  );
}

export default AdminRoute;
