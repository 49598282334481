import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import React from "react";
import { useHistory } from "react-router-dom";
// import EnvSettings from "../../Utils/EnvSettings";
import { useStyles } from "../Utils/Styles";

// const server = EnvSettings.server;

export default function BadgeLink({
  href,
  title,
  icon: Icon,
  image: Image,
  iconStyle,
  style,
  disabled,
}) {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, {
    height: 200,
    cursor: "pointer",
    minWidth: 300,
  });
  const history = useHistory();
  return (
    <Grid item style={{ cursor: "pointer" }}>
      <Paper
        onClick={() => {
          if (disabled) {
            return;
          }
          history.push(href);
        }}
        className={fixedHeightPaper}
        style={disabled ? { backgroundColor: "lightgrey" } : {}}
      >
        <Grid container justify="center" direction="column" alignItems="center">
          {Icon ? (
            <Grid item>
              <Icon style={iconStyle} />
            </Grid>
          ) : Image ? (
            <Grid item>{Image}</Grid>
          ) : null}
          <Grid item>
            <Typography style={{ textAlign: "center" }}>{title}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
