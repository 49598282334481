import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import SortableTable from "../../../Components/SortableTable";
import Title from "../../../Components/Title";
import {
  deviceProfiles,
  ECABIN,
  formatTimeWithTZOffset,
  getLastParameter,
  getToken,
  requestErrorHandler,
} from "../../../Utils/Common";
import EnvSettings from "../../../Utils/EnvSettings";
import { useStyles } from "../../../Utils/Styles";

const server = EnvSettings.server;

function ReleaseToProductionDialog({ swpackage, setPackage, submit }) {
  const { t } = useTranslation();
  const [descriptionText, setDescriptionText] = useState();

  useEffect(() => {
    if (swpackage) {
      setDescriptionText(swpackage.version + "\n\n");
    }
  }, [swpackage]);

  const checkValidity = (data) => {
    if (data.length < 7) {
      return false;
    }
    if (!data.includes(swpackage.version + "\n\n")) {
      return false;
    }
    return true;
  };
  const handleExit = () => {
    setPackage();
    setDescriptionText();
  };

  return swpackage ? (
    <Dialog open={!!swpackage} onClose={handleExit}>
      <DialogTitle>Release {swpackage.version} to Production?</DialogTitle>
      <DialogContent>
        <TextField
          style={{ width: 500 }}
          multiline
          rows={10}
          fullWidth
          variant="outlined"
          label={"Release Description"}
          value={descriptionText}
          onChange={(e) => {
            if (!checkValidity(e.target.value)) {
              return;
            }
            setDescriptionText(e.target.value);
          }}
        ></TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleExit} variant="contained" color="secondary">
          {t("no")}
        </Button>
        <Button
          onClick={() => {
            submit(descriptionText);
          }}
          variant="contained"
          color="primary"
        >
          {t("yes")}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
}

export default function PrereleaseList() {
  const classes = useStyles();
  const [softwarePackages, setSoftwarePackages] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const type = getLastParameter();
  const [swpackage, setPackage] = useState();
  const formatSWData = (data, rels) => {
    var arr = [];
    // data = testData;
    if (data && Array.isArray(data)) {
      data.forEach((d) => {
        var obj = {
          filename: d.filename,
          version: d.filename.replace(".zip", "").split("").join("."),
          date: formatTimeWithTZOffset(d.date, ECABIN).substring(0, 10),
          count: 0,
          percentage: 0,
          released:
            rels && rels.length
              ? !!rels.find((f) => f.filename === d.filename)
              : false,
        };
        arr.push(obj);
      });
    }
    return arr;
  };

  const getReleases = (packages) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        type: type,
      }),
    };
    fetch(server + "/get_software_packages", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          getDevices(formatSWData(packages, result.packages));
        }
      });
  };

  const getPrereleases = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        prerelease: true,
        type: type,
      }),
    };
    fetch(server + "/get_software_packages", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          getReleases(result.packages);
        }
      });
  };

  const getDevices = (packages) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        requireLCS: false,
        search_value:
          deviceProfiles.find(
            (f) =>
              f.name.replace("°", "").replace(" ", "").toLowerCase() === type
          )?.prefix || "EC2",
      }),
    };
    fetch(server + "/get_admin_devices", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          if (result.devices) {
            var arr = packages;
            result.devices.forEach((d) => {
              var i = packages.findIndex((s) => s.version === d.version);
              if (i === -1) {
                return;
              }
              var s = arr[i];
              s.count = s.count + 1;
              s.percentage = s.count / result.devices.length;
              arr[i] = s;
            });
            setSoftwarePackages(arr);
          }
        }
      });
  };

  const releasePackage = (description) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        filename: swpackage.filename,
        product: type,
        description_text: description,
      }),
    };
    fetch(server + "/publish_software_to_production", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        window.location.reload();
      });
  };

  const handleRowClick = (data) => {
    if (!data || data.released) {
      return;
    }
    setPackage(data);
  };

  useEffect(getPrereleases, []);

  return (
    <Grid container spacing={3}>
      <ReleaseToProductionDialog
        swpackage={swpackage}
        setPackage={setPackage}
        submit={releasePackage}
      />
      <Grid item xs>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href={"/cpanel/software"}>
            {t("software.header")}
          </Link>
          <Link color="inherit" href={"/cpanel/software/releases"}>
            {t("software.releases")}
          </Link>
          <Link color="inherit" href={"/cpanel/software/releases/" + type}>
            {type.toUpperCase()}
          </Link>
          <Typography color="textPrimary">{"Prereleases"}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Title>Software prereleases: {type.toUpperCase()}</Title>
          {softwarePackages ? (
            <SortableTable
              headCells={[
                {
                  id: "version",
                  numeric: false,
                  disablePadding: true,
                  label: t("version"),
                },
                {
                  id: "date",
                  numeric: false,
                  disablePadding: true,
                  label: t("releaseDate"),
                },
                {
                  id: "count",
                  numeric: false,
                  disablePadding: true,
                  label: "Active installs",
                },
                {
                  id: "percentage",
                  numeric: false,
                  disablePadding: true,
                  label: "%",
                },
                {
                  id: "released",
                  numeric: false,
                  disablePadding: true,
                  label: "Released",
                },
              ]}
              defaultSortRow={"date"}
              handleRowClick={handleRowClick}
              tableData={softwarePackages}
              rowsPerPage={200}
              disablePagination={false}
              tableSize={"small"}
              specialDataFormatting={[
                {
                  id: "percentage",
                  format: (d) => (d * 100).toFixed(2),
                },
                {
                  id: "released",
                  format: (d) => (d ? t("yes") : t("no")),
                },
              ]}
            />
          ) : (
            <CircularProgress />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
