import { Dialog, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getToken, requestErrorHandler } from "../Utils/Common";
import EnvSettings from "../Utils/EnvSettings";
import { useStyles } from "../Utils/Styles";
import { showPopupSnackbar } from "../redux/actions/snackbarActions";
import Title from "./Title";

const server = EnvSettings.server;

/**
 *
 * @param {*} resource { type, number }
 * @param {*} listCallback
 * @returns
 */
export default function MediaFiles({ resource, listCallback }) {
  // Endpoints get, upload, delete
  // Resource { type: , number: }

  const classes = useStyles();
  const [checklistURL, setChecklistURL] = useState();
  // const [pdfURL, setPdfURL] = useState();
  const [files, setFiles] = useState();
  const [fileToDelete, setFileToDelete] = useState();
  const { t } = useTranslation();

  const fileUploadRef = useRef();

  const dispatch = useDispatch();

  const getPhotos = () => {
    setChecklistURL();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        resource: resource,
      }),
    };
    fetch(server + "/get_media_files", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          if (result.files) {
            setChecklistURL(result);
            if (listCallback) {
              listCallback(result);
            }
          }
        }
      });
  };

  useEffect(() => {
    if (!resource || !resource.number) {
      return;
    }
    getPhotos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resource, dispatch]);

  const getContentType = (val, blob) => {
    if (val && val.includes(".pdf")) {
      return new Blob([blob], { type: "application/pdf" });
    }
    var arr = val.split(".");
    if (arr.length) {
      return new Blob([blob], { type: "image/" + arr[arr.length - 1] });
    }
  };

  const uploadPhoto = (e) => {
    if (!files && !!files.length) {
      return;
    }
    const data = new FormData();
    data.append("token", getToken());
    files.forEach((element) => {
      data.append("files[]", element);
    });
    data.append("resource", JSON.stringify(resource));
    fetch(server + "/upload_media_files", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        dispatch(showPopupSnackbar(result));
        if (!result.error) {
          getPhotos();
          fileUploadRef.current.value = null;
        }
      });
  };

  const removePhoto = () => {
    if (!fileToDelete && !resource?.number) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        filename: fileToDelete,
        resource: resource,
      }),
    };
    fetch(server + "/delete_media_file", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          dispatch(showPopupSnackbar(result));
        }
        getPhotos();
      });
  };

  const showPhoto = (val, dir) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        filename: val,
        file_location: dir,
      }),
    };
    fetch(server + "/show_checklist", requestOptions)
      .then((response) => {
        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close();
                  return;
                }
                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });
      })
      // Create a new response out of the stream
      .then((stream) => new Response(stream))
      // Create an object URL for the response
      .then((response) => response.blob())
      .then((blob) => {
        // setPdfURL(blob);
        const fileName = "checklist.pdf";

        const newBlob = getContentType(val, blob);

        const newWindow = window.open("", fileName, "width=800,height=1200");
        if (newWindow) {
          setTimeout(() => {
            const dataUrl = window.URL.createObjectURL(newBlob);
            const title = newWindow.document.createElement("title");
            const iframe = newWindow.document.createElement("iframe");

            title.appendChild(document.createTextNode(fileName));
            newWindow.document.head.appendChild(title);

            iframe.setAttribute("width", "99%");
            iframe.setAttribute("height", "99%");

            iframe.setAttribute("src", dataUrl);

            newWindow.document.body.appendChild(iframe);

            setTimeout(() => {
              // For Firefox it is necessary to delay revoking the ObjectURL
              // if (!val.includes(".pdf")) {
              //   newWindow.document.body
              //     .getElementsByTagName("iframe")[0]
              //     .contentDocument.getElementsByTagName("img")[0]
              //     .setAttribute("style", "height:-webkit-fill-available;");
              // }
              window.URL.revokeObjectURL(dataUrl);
            }, 100);
          }, 100);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleClose = () => {
    setFileToDelete(undefined);
  };

  return resource && resource.number ? (
    <Grid container spacing={3}>
      <Dialog
        open={fileToDelete ? true : false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("delete")} {fileToDelete}?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("cancel")}
          </Button>
          <Button
            onClick={() => {
              removePhoto();
              handleClose();
            }}
            color="primary"
            autoFocus
          >
            {t("delete")}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={3} item xs={12} sm={6}>
        <Grid item xs={12}>
          <Title>{t("media")}</Title>
        </Grid>
        {checklistURL &&
        checklistURL.files.filter((f) => !f.includes("packing_list")).length ? (
          checklistURL.files.map((f, i) => (
            <Grid key={i} item xs={12}>
              <Button
                variant="outlined"
                onClick={() => showPhoto(f, checklistURL.root_dir)}
              >
                {f}
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  setFileToDelete(f);
                }}
              >
                {t("delete")}
              </Button>
            </Grid>
          ))
        ) : (
          <Grid item>
            <Typography>{t("noMediaAvailable")}</Typography>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} sm={6}>
        <Grid item xs={12}>
          <Title>{t("uploadNewMedia")}</Title>
        </Grid>
        <Grid item>
          <input
            accept="image/*,.pdf"
            className={classes.input}
            id="contained-button-file"
            type="file"
            multiple
            onChange={(e) => {
              if (e.target.files) {
                setFiles(Array.prototype.slice.call(e.target.files));
              }
            }}
            ref={fileUploadRef}
          />
        </Grid>
        <Grid item>
          <label htmlFor="contained-button-file">
            <Button
              variant="outlined"
              disabled={!(files && files.length) ? true : false}
              onClick={uploadPhoto}
            >
              {t("uploadMedia")}
            </Button>
          </label>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Typography>{t("ticketList.submitTicketFirst")}!</Typography>
  );
}
