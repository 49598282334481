import { IconButton, MenuItem, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Clear from "@material-ui/icons/Clear";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import StockItemSearch from "../../../../Components/StockItems/StockItemSearch";
import Title from "../../../../Components/Title";

export default function StockItemSection({
  unedited,
  emaillist,
  setEmailList,
}) {
  unedited = unedited || emaillist;
  const { t } = useTranslation();
  const [resetKey, setResetKey] = useState("sdfasfsda");

  return (
    <Grid item sm={3} xs={12}>
      <Title>{t("emailLists.stock")}</Title>
      {unedited &&
        unedited.EmailListStockItems &&
        unedited.EmailListStockItems.map((u) =>
          u && u.StockItem ? (
            <MenuItem
              style={
                emaillist.EmailListStockItems.find(
                  (uu) => uu.StockItem?.name === u.StockItem?.name
                )
                  ? {}
                  : { textDecoration: "line-through" }
              }
            >
              <Grid container direction="column">
                <Grid item>
                  <Typography style={{ fontSize: 15 }}>
                    {u.StockItem.sku}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography style={{ fontSize: 12 }}>
                    {u.StockItem.name}
                  </Typography>
                </Grid>
              </Grid>
              <IconButton
                style={{ position: "absolute", right: 0 }}
                onClick={() => {
                  setEmailList({
                    ...emaillist,
                    EmailListStockItems: unedited.EmailListStockItems.filter(
                      (f) => f.StockItem?.id !== u.StockItem?.id
                    ),
                  });
                }}
              >
                <Clear />
              </IconButton>
            </MenuItem>
          ) : null
        )}
      {emaillist &&
        unedited &&
        emaillist.EmailListStockItems &&
        emaillist.EmailListStockItems.filter(
          (u) =>
            !unedited.EmailListStockItems.length ||
            !unedited.EmailListStockItems.find(
              (f) => u.StockItem?.id === f.StockItem?.id
            )
        ).map((u, i) => (
          <MenuItem
            key={i}
            style={{
              backgroundColor: "rgb(177 255 184)",
            }}
          >
            <Grid container direction="column">
              <Grid item>
                <Typography style={{ fontSize: 15 }}>
                  {u.StockItem.sku}
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ fontSize: 12 }}>
                  {u.StockItem.name}
                </Typography>
              </Grid>
            </Grid>
            <IconButton
              style={{ position: "absolute", right: 0 }}
              onClick={() => {
                setEmailList({
                  ...emaillist,
                  EmailListStockItems: emaillist.EmailListStockItems.filter(
                    (f) => f.StockItem?.id !== u.StockItem?.id
                  ),
                });
              }}
            >
              <Clear />
            </IconButton>
          </MenuItem>
        ))}
      <StockItemSearch
        allowNewItems={false}
        clearOnSelect={true}
        includeEmailLists={true}
        label={t("emailLists.searchStockItem")}
        onChange={(e, v) => {
          if (!v || !v.name) {
            return;
          }
          setEmailList({
            ...emaillist,
            EmailListStockItems: [
              ...(emaillist.EmailListStockItems || []),
              { StockItem: v },
            ],
          });
        }}
      />
    </Grid>
  );
}
