import Grid from "@material-ui/core/Grid";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import ArchiveIcon from "@material-ui/icons/Archive";
import Build from "@material-ui/icons/Build";
import Business from "@material-ui/icons/Business";
import DevicesOther from "@material-ui/icons/DevicesOther";
import DirectionsBoatIcon from "@material-ui/icons/DirectionsBoat";
import DonutSmallIcon from "@material-ui/icons/DonutSmall";
import Equalizer from "@material-ui/icons/Equalizer";
import Receipt from "@material-ui/icons/Receipt";
import Settings from "@material-ui/icons/Settings";
import Store from "@material-ui/icons/Store";
import React from "react";
import { useTranslation } from "react-i18next";
import BadgeLink from "../../Components/BadgeLink";
import { getUser, USER_GROUPS } from "../../Utils/Common";
// import EnvSettings from "../../Utils/EnvSettings";

// const server = EnvSettings.server;

/**
 *
 * Moduuli softalle --> julkaisut, asennuksien lukumäärät ja kaatumiset
 * Moduuli laitteille --> ajot ja niiden tilastot == Dashboard
 * Moduuli siirroille --> uusimmat siirrot
 * Moduuli huollolle --> uusimmat tiketit ja huomautukset
 * Moduuli asiakkaille
 *
 * @param {*} props
 * @returns
 */

export default function ControlPanel(props) {
  const { t } = useTranslation();
  const badgeLinks = [
    {
      href: "/cpanel/software",
      title: t("software.header"),
      icon: ArchiveIcon,
      superuser: true,
    },
    {
      href: "/cpanel/devices/dashboard",
      title: t("devices.header"),
      icon: DevicesOther,
    },
    {
      href: "/cpanel/production",
      title: t("lifeCycleManagement.header"),
      icon: DonutSmallIcon,
    },
    {
      href: "/cpanel/maintenance",
      title: t("maintenance.header"),
      icon: Build,
    },
    {
      href: "/cpanel/contacts",
      title: t("contacts.header"),
      icon: Business,
    },
    {
      href: "/cpanel/system",
      title: t("system.header"),
      icon: Settings,
      superuser: true,
    },
    {
      href: "/cpanel/stock",
      title: t("stock.header"),
      icon: Equalizer,
    },
    {
      href: "/cpanel/orders",
      title: t("orders.header"),
      icon: Receipt,
    },
    {
      href: "/cpanel/resupply",
      title: t("resupplyOrders.header"),
      icon: AddShoppingCartIcon,
    },
    {
      href: "/cpanel/webstore",
      title: t("webstoreOrders.header"),
      icon: Store,
    },
    {
      href: "/cpanel/shipments",
      title: t("shipments.header"),
      icon: DirectionsBoatIcon,
    },
  ];

  return (
    <Grid container justify="center">
      <Grid container spacing={3} justify="center" item md={12} lg={6}>
        {/* All the admin items as badges */}
        {badgeLinks
          .sort((a, b) => {
            return a.title > b.title ? 1 : -1;
          })
          .map((b, i) =>
            b.superuser &&
            getUser().permissionlevel !== USER_GROUPS.SUPER_USER ? null : (
              <BadgeLink key={i} href={b.href} title={b.title} icon={b.icon} />
            )
          )}
      </Grid>
    </Grid>
  );
}
