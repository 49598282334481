import {
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  IconButton,
  ListItem,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  withWidth,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ChatIcon from "@material-ui/icons/Chat";
import Description from "@material-ui/icons/Description";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ControlledSelect from "../../../Components/ControlledSelect";
import DialogAppBar from "../../../Components/DialogAppBar";
import EditableTypography from "../../../Components/EditableTypography";
import MediaFiles from "../../../Components/MediaFiles";
import NoteSection from "../../../Components/NoteSection";
import StockItemSearch from "../../../Components/StockItems/StockItemSearch";
import Title from "../../../Components/Title";
import {
  ECABIN,
  formatTimeWithTZOffset,
  getToken,
  invoiceIssuers,
  paymentStates,
  requestErrorHandler,
  shipmentStatus,
  USER_GROUPS,
} from "../../../Utils/Common";
import EnvSettings from "../../../Utils/EnvSettings";
import i18n from "../../../i18n";
import { showPopupSnackbar } from "../../../redux/actions/snackbarActions";
import DetailTabs from "../../DeviceDetails/DetailTabs";
import InvoicePreviewWindow from "../InvoicePreviewWindow";
import OrderLine from "../OrderLine";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import FindSerialNumbers from "./FindSerialNumbers";
import ForceDevsDialog from "./ForceDevsDialog";

const server = EnvSettings.server;

function EditOrder({ selectedOrder, width }) {
  const [editable, setEditable] = useState(false);
  const [editedOrder, setEditedOrder] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [forceDevs, setForceDevs] = useState(false);
  const [productFilter, setProductFilter] = useState(false);
  const [showForceDevs, setShowForceDevs] = useState(false);
  const [automaticSerNo, setAutomaticSerNo] = useState(false);
  const [fetchedDevs, setFetchedDevs] = useState();
  const [showPreview, setShowPreview] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [editShipping, setEditShipping] = useState(false);
  const [editDetails, setEditDetails] = useState(false);
  const [invoiceLang, setInvoiceLang] = useState();
  const [refrs, setRefresh] = useState("sdfsadfasdfasdf");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const deleteOrder = () => {
    if (!editedOrder) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        orderData: editedOrder,
      }),
    };
    fetch(server + "/delete_order", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          window.location.reload();
        }
      });
  };

  const getDevices = (ser_val, searchRestrictions, count) => {
    if (!ser_val) {
      return;
    }
    // setLoadingDevicesList(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        search_value: ser_val,
        requireLCS: false,
        ...(searchRestrictions ? searchRestrictions : {}),
      }),
    };
    fetch(server + "/get_admin_devices", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          if (!result.devices.length) {
            dispatch(
              showPopupSnackbar({
                message: "No devices available!",
                error: true,
              })
            );
            return;
          }
          if (count < 0) {
            return;
          }
          var arr = result.devices;
          if (result.devices.length < count) {
            arr = arr.slice(0, arr.length);
            dispatch(
              showPopupSnackbar({
                message: "Not enough devices available!",
                error: true,
              })
            );
          } else {
            arr = arr.slice(0, count);
          }
          setFetchedDevs(arr);
        }
      });
  };

  const editOrder = () => {
    if (!editedOrder) {
      return;
    }
    if (
      JSON.stringify(selectedOrder.genericDevices) !==
      JSON.stringify(editedOrder.genericDevices)
    ) {
      setShowPreview(true);
      setSubmit(true);
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        orderData: editedOrder,
        forceDevs: forceDevs,
      }),
    };
    fetch(server + "/edit_order", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          window.location.reload();
        }
        if (result.error && result.orders) {
          setShowForceDevs(result.orders);
        }
      });
  };

  useEffect(() => {
    if (forceDevs) {
      editOrder();
    }
    // eslint-disable-next-line
  }, [forceDevs]);

  const getContentType = (val, blob) => {
    return new Blob([blob], { type: "application/pdf" });
  };

  const showInvoice = (val, callback, check, download) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        filename: val + ".pdf",
        file_location: "./invoices/" + val.replace("packing_list_", ""),
      }),
    };
    fetch(server + "/show_checklist", requestOptions)
      .then((response) => {
        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close();
                  return;
                }
                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });
      })
      // Create a new response out of the stream
      .then((stream) => {
        return new Response(stream);
      })
      // Create an object URL for the response
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        // setPdfURL(blob);
        if (callback && (check || blob.size < 150)) {
          return callback(blob.size > 150);
        }
        if (download) {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", val + ".pdf");

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
          return;
        }

        const fileName = "checklist.pdf";

        const newBlob = getContentType(val, blob);

        const newWindow = window.open("", fileName, "width=800,height=1200");
        if (newWindow) {
          setTimeout(() => {
            const dataUrl = window.URL.createObjectURL(newBlob);
            const title = newWindow.document.createElement("title");
            const iframe = newWindow.document.createElement("iframe");

            title.appendChild(document.createTextNode(fileName));
            newWindow.document.head.appendChild(title);

            iframe.setAttribute("width", "99%");
            iframe.setAttribute("height", "99%");

            iframe.setAttribute("src", dataUrl);

            newWindow.document.body.appendChild(iframe);

            setTimeout(() => {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(dataUrl);
            }, 100);
          }, 100);
        }
      })
      .catch((err) => {
        console.error(err);
        if (callback) {
          callback();
        }
      });
  };

  const allowItemChanges = () => {
    if (editedOrder.paymentStatus === "delivered") {
      return false;
    }
    if (editedOrder.paymentStatus === "transit") {
      return false;
    }
    return editable;
  };

  const checkAllowedPaymentStates = () => {
    var list = [];
    var current = paymentStates.findIndex(
      (p) => p.value === selectedOrder?.paymentStatus
    );
    var obj;
    paymentStates.forEach((p, i) => {
      obj = { ...p };
      if (i < current) {
        obj = { ...obj, disabled: true };
      }
      list.push(obj);
    });
    return list;
  };
  useEffect(() => {
    if (selectedOrder) {
      setEditedOrder(JSON.parse(JSON.stringify(selectedOrder)));
    }
    // eslint-disable-next-line
  }, [selectedOrder, setEditedOrder]);

  // This func checks if the autoserdialog will be showed
  const checkForAutoSer = (e) => {
    if (!(e.target.value === "deposit" || e.target.value === "completed")) {
      return;
    }
    if (!editedOrder.genericDevices) {
      return;
    }
    if (
      !editedOrder.genericDevices.find(
        (f) => f.sku && (f.sku.includes("_EC_G_") || f.sku.includes("_EC_W_"))
      )
    ) {
      return;
    }
    var ordered_greys = editedOrder.genericDevices.find(
      (f) => f.sku && f.sku.includes("_EC_G_")
    )?.count;
    var allocated_greys = editedOrder.Devices.filter(
      (f) => f.color === "Grey"
    ).length;
    var ordered_whites = editedOrder.genericDevices.find(
      (f) => f.sku && f.sku.includes("_EC_W_")
    )?.count;
    var allocated_whites = editedOrder.Devices.filter(
      (f) => f.color === "White"
    ).length;
    if (
      (ordered_greys && ordered_greys !== allocated_greys) ||
      (ordered_whites && ordered_whites !== allocated_whites)
    ) {
      setAutomaticSerNo(true);
    }
  };

  useEffect(() => {
    if (fetchedDevs) {
      setEditedOrder({
        ...editedOrder,
        Devices: [...editedOrder.Devices, ...fetchedDevs],
      });
    }
    // eslint-disable-next-line
  }, [fetchedDevs]);

  const handleExit = () => {
    setEditable(false);
    setEditedOrder();
    setEditShipping(false);
    setEditDetails(false);
  };

  return editedOrder ? (
    <>
      <Dialog open={!!editedOrder} fullScreen onClose={handleExit}>
        <DialogContent>
          <DialogAppBar
            header={t("orders.orderDetails")}
            onClose={handleExit}
          />
          <Grid
            container
            spacing={1}
            style={{
              marginTop: 60,
              marginBottom: 20,
              paddingBottom: 30,
              position: "relative",
            }}
          >
            <ConfirmDeleteDialog
              onClose={() => {
                setShowDialog(false);
              }}
              showDialog={showDialog}
              deleteOrder={deleteOrder}
            />
            <FindSerialNumbers
              automaticSerNo={automaticSerNo}
              getDevices={getDevices}
              setAutomaticSerNo={setAutomaticSerNo}
              editedOrder={editedOrder}
            />
            <ForceDevsDialog
              setForceDevs={setForceDevs}
              showForceDevs={showForceDevs}
              setShowForceDevs={setShowForceDevs}
            />
            <Grid item sm={6} xs={12} style={{ minHeight: 300 }}>
              <Typography style={{ fontWeight: "bold" }}>
                {t("orders.billingInfo")}
              </Typography>
              <Typography>{editedOrder.clientInfo.companyName}</Typography>{" "}
              {editedOrder.clientInfo.companyAddress
                ? editedOrder.clientInfo.companyAddress
                    .split("\n")
                    .map((o, i) =>
                      o.includes(",") ? (
                        o
                          .split(",")
                          .map((v, ii) => <Typography key={ii}>{v}</Typography>)
                      ) : (
                        <Typography key={i}>{o}</Typography>
                      )
                    )
                : null}
              <Typography>
                VAT ID: {editedOrder.clientInfo.companyVatId}
              </Typography>
              <Typography>{"\n"}</Typography>
              <Typography>{editedOrder.clientInfo.contactName}</Typography>{" "}
              <Typography>{editedOrder.clientInfo.contactPhone}</Typography>
              <Typography>
                {" "}
                {editedOrder.clientInfo.contactEmail}
              </Typography>{" "}
              {editedOrder.shippingInfo ? (
                <>
                  <Typography style={{ marginTop: 20, fontWeight: "bold" }}>
                    {t("orders.shippingInfo")}
                    <Tooltip title={`${t("edit")}`}>
                      <IconButton
                        onClick={() => {
                          setEditShipping(!editShipping);
                        }}
                        style={{
                          display: allowItemChanges() ? "" : "none",
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                  <EditableTypography
                    edit={editShipping}
                    onChange={(e, v) => {
                      setEditedOrder({
                        ...editedOrder,
                        shippingInfo: {
                          ...editedOrder.shippingInfo,
                          companyName: e.target.value,
                        },
                      });
                    }}
                    name={t("contactInfo.companyName")}
                  >
                    {editedOrder.shippingInfo.companyName}
                  </EditableTypography>
                  <EditableTypography
                    edit={editShipping}
                    onChange={(e, v) => {
                      setEditedOrder({
                        ...editedOrder,
                        shippingInfo: {
                          ...editedOrder.shippingInfo,
                          companyAddress: e.target.value,
                        },
                      });
                    }}
                    name={t("contactInfo.companyAddress")}
                    multiline
                  >
                    {editedOrder.shippingInfo.companyAddress}
                  </EditableTypography>
                  <EditableTypography
                    edit={editShipping}
                    prefix={"VAT ID:"}
                    onChange={(e, v) => {
                      setEditedOrder({
                        ...editedOrder,
                        shippingInfo: {
                          ...editedOrder.shippingInfo,
                          companyVatId: e.target.value,
                        },
                      });
                    }}
                  >
                    {editedOrder.shippingInfo.companyVatId
                      ? editedOrder.shippingInfo.companyVatId
                      : ""}
                  </EditableTypography>
                  <EditableTypography
                    edit={editShipping}
                    onChange={(e, v) => {
                      setEditedOrder({
                        ...editedOrder,
                        shippingInfo: {
                          ...editedOrder.shippingInfo,
                          contactName: e.target.value,
                        },
                      });
                    }}
                    name={t("contactInfo.contactName")}
                  >
                    {editedOrder.shippingInfo.contactName}
                  </EditableTypography>
                  <EditableTypography
                    edit={editShipping}
                    onChange={(e, v) => {
                      setEditedOrder({
                        ...editedOrder,
                        shippingInfo: {
                          ...editedOrder.shippingInfo,
                          contactPhone: e.target.value,
                        },
                      });
                    }}
                    name={t("contactInfo.contactPhone")}
                  >
                    {editedOrder.shippingInfo.contactPhone}
                  </EditableTypography>
                  <EditableTypography
                    edit={editShipping}
                    onChange={(e, v) => {
                      setEditedOrder({
                        ...editedOrder,
                        shippingInfo: {
                          ...editedOrder.shippingInfo,
                          contactEmail: e.target.value,
                        },
                      });
                    }}
                    name={t("contactInfo.contactEmail")}
                  >
                    {editedOrder.shippingInfo.contactEmail}
                  </EditableTypography>
                </>
              ) : allowItemChanges() ? (
                <Button
                  variant="outlined"
                  onClick={() => {
                    setEditedOrder({
                      ...editedOrder,
                      shippingInfo: { unknownReceiver: true },
                    });
                    setEditShipping(true);
                  }}
                >
                  {t("orders.addShippingAddress")}?
                </Button>
              ) : null}
            </Grid>
            <Grid item container sm={6} xs={12}>
              <Grid item xs={12}>
                <Typography>
                  {t("orders.orderNumber")}: {editedOrder.number}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {t("orders.invoiceIssuer")}:{" "}
                  {
                    Object.entries(invoiceIssuers).find(([k, o], v) => {
                      if (k === editedOrder.invoiceIssuer) {
                        return o;
                      }
                      return undefined;
                    })[1].name
                  }
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {t("createdBy")}: {editedOrder.User.fullName}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {t("createdAt")}:{" "}
                  {formatTimeWithTZOffset(editedOrder.createdAt, ECABIN)}
                </Typography>
              </Grid>
              {editedOrder.paymentDate ? (
                <Grid item xs={12}>
                  <Typography>
                    {t("orders.paymentDate")}:{" "}
                    {formatTimeWithTZOffset(editedOrder.paymentDate, ECABIN)}
                  </Typography>
                </Grid>
              ) : null}
              {editedOrder.deliveryDate ? (
                <Grid item xs={12}>
                  <Typography>
                    {t("orders.deliveryDate")}:{" "}
                    {formatTimeWithTZOffset(editedOrder.deliveryDate, ECABIN)}
                  </Typography>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <ControlledSelect
                  itemlist={checkAllowedPaymentStates()}
                  label={t("orders.paymentStatus")}
                  defaultValue={editedOrder.paymentStatus}
                  onChange={(e, v) => {
                    if (!e.target?.value) {
                      setEditedOrder({
                        ...editedOrder,
                        paymentStatus: selectedOrder.paymentStatus,
                      });
                      return;
                    }
                    setEditedOrder({
                      ...editedOrder,
                      paymentStatus: e.target.value,
                    });
                    checkForAutoSer(e);
                  }}
                  deleteButton={true}
                  disabled={!editable}
                  usingTranslation={(data) => {
                    return t("paymentStates." + data);
                  }}
                />
              </Grid>
              {editedOrder.paymentStatus === "deposit" ? (
                <Grid item xs={12} style={{ marginTop: 5 }}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label={t("orders.depositAmount")}
                    type="number"
                    defaultValue={editedOrder.depositAmount || ""}
                    disabled={!editable}
                    inputProps={{ min: 0, max: editedOrder.totalPrice }}
                    onChange={(e, v) => {
                      if (!e.target?.value) {
                        setEditedOrder({ ...editedOrder, depositAmount: null });
                        return;
                      }
                      setEditedOrder({
                        ...editedOrder,
                        depositAmount: e.target.value,
                      });
                    }}
                  ></TextField>
                </Grid>
              ) : null}
              {editedOrder.number ? (
                <Grid item xs={12}>
                  <Button
                    onClick={() => {
                      showInvoice(editedOrder.number);
                    }}
                    startIcon={<Description />}
                    variant="outlined"
                  >
                    {t("show") + " " + t("invoice")}
                  </Button>
                </Grid>
              ) : null}
              {editedOrder.Shipments ? (
                <>
                  <Title>
                    {t("shipments.header")}
                    {editedOrder.multipleShipments ? (
                      <Tooltip
                        title={
                          <Typography style={{ fontSize: 15 }}>
                            {t("orders.multipleShipments")}
                          </Typography>
                        }
                      >
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </Title>

                  {editedOrder.Shipments.map((m, i) => (
                    <Tooltip title={"Show Shipment"} key={i}>
                      <ListItem
                        button
                        onClick={() => {
                          history.push("/cpanel/shipments/" + m.number);
                        }}
                        style={{
                          border: "1px solid #000",
                          backgroundColor:
                            shipmentStatus.find((f) => f.value === m.status)
                              ?.color || "",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography style={{ fontSize: 15 }}>
                              {m.number} -{" "}
                              {t(
                                "shipmentStates." +
                                  (shipmentStatus.find(
                                    (f) => f.value === m.status
                                  )?.value || "waiting")
                              )}
                            </Typography>
                          </Grid>
                          {m.genericDevices &&
                            m.genericDevices.map((mm, i) =>
                              mm.count ? (
                                <Grid item xs={12} key={i}>
                                  <Typography style={{ fontSize: 12 }}>
                                    {mm.name + " x " + mm.count}
                                  </Typography>
                                </Grid>
                              ) : null
                            )}
                        </Grid>
                      </ListItem>
                    </Tooltip>
                  ))}
                  {editedOrder.paymentStatus === "completed" ||
                  editedOrder.paymentStatus === "transit" ||
                  editedOrder.paymentStatus === "deposit" ? (
                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          history.push(
                            "/cpanel/shipments/new/order/" + editedOrder.number
                          );
                        }}
                        style={{ marginTop: 5 }}
                      >
                        {t("orders.addNewShipment")}
                      </Button>
                    </Grid>
                  ) : null}
                </>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: "bold" }}>
                {t("details")}
                <Tooltip title={`${t("edit")}`}>
                  <IconButton
                    style={{
                      display: editable ? "" : "none",
                    }}
                    onClick={() => {
                      setEditDetails(!editDetails);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
              <EditableTypography
                edit={editDetails}
                name={t("details")}
                onChange={(e, v) => {
                  setEditedOrder({ ...editedOrder, details: e.target.value });
                }}
              >
                {editedOrder.details}
              </EditableTypography>
            </Grid>
            <Grid item xs={12}>
              <DetailTabs
                activeTabs={[
                  {
                    permission: [USER_GROUPS.ADMIN, USER_GROUPS.SUPER_USER],
                    label: (
                      <>
                        <ShoppingCartIcon /> {t("items")}
                      </>
                    ),
                    content: (
                      <>
                        <Grid item xs={12}>
                          {allowItemChanges() ? (
                            <>
                              <Grid item xs={12}>
                                <Title>{t("orders.itemSearch")}</Title>
                              </Grid>
                              <Grid item xs={12}>
                                <FormControlLabel
                                  defaultChecked={false}
                                  control={
                                    <Checkbox
                                      onChange={(e, v) => {
                                        setProductFilter(!productFilter);
                                      }}
                                      defaultChecked={false}
                                      color="primary"
                                    />
                                  }
                                  label={t("orders.showOnlyDevices")}
                                />
                              </Grid>
                              <StockItemSearch
                                allowNewItems={false}
                                onChange={(e, v) => {
                                  if (v && v.sku) {
                                    if (
                                      editedOrder.genericDevices.find(
                                        (g) => g.sku === v.sku
                                      )
                                    ) {
                                      alert(v.sku + " already added!");
                                      return;
                                    }
                                    var obj = JSON.parse(
                                      JSON.stringify(editedOrder)
                                    );
                                    obj = {
                                      ...obj,
                                      genericDevices: [
                                        ...obj.genericDevices,
                                        {
                                          ...v,
                                          count: 1,
                                          price: v.listPrice,
                                          discount: 0,
                                        },
                                      ],
                                      totalPrice:
                                        obj.totalPrice + (v.listPrice || 0),
                                    };
                                    setEditedOrder(obj);
                                    setRefresh(Date.now());
                                  }
                                }}
                                clearOnSelect={true}
                                includeQuantity={true}
                                includeTags={true}
                                includePacking={true}
                                placeholder={t("orders.startSearchingProducts")}
                                filters={
                                  productFilter
                                    ? {
                                        Tags: [
                                          "eCabin",
                                          "CryoCabin",
                                          "XCryo",
                                          "XTone",
                                          "OxyPro",
                                          "LedPro",
                                          "XGun",
                                        ],
                                      }
                                    : undefined
                                }
                              />{" "}
                            </>
                          ) : null}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          container
                          style={{ borderTop: "1px solid #000", marginTop: 10 }}
                        >
                          <Grid item sm={3} xs={3}>
                            <Typography>{t("description")}</Typography>
                          </Grid>
                          {allowItemChanges() ? (
                            <Grid item xs={4}></Grid>
                          ) : null}
                          <Grid item sm={allowItemChanges() ? 1 : 2} xs={3}>
                            <Typography style={{ textAlign: "right" }}>
                              {t("orders.numberItems")}
                            </Typography>
                          </Grid>
                          <Grid item sm={allowItemChanges() ? 1 : 2} xs={3}>
                            <Typography style={{ textAlign: "right" }}>
                              {t("orders.unitPrice")}
                            </Typography>
                          </Grid>
                          <Grid item sm={allowItemChanges() ? 1 : 2} xs={3}>
                            <Typography style={{ textAlign: "right" }}>
                              {t("orders.discount") + "%"}
                            </Typography>
                          </Grid>
                          <Grid item sm={allowItemChanges() ? 1 : 2} xs={3}>
                            <Typography style={{ textAlign: "right" }}>
                              {t("orders.unitTotal")}
                            </Typography>
                          </Grid>
                          {width === "xs" ? null : <Grid item xs={1}></Grid>}
                        </Grid>
                        {editedOrder.genericDevices &&
                          editedOrder.genericDevices.map((dev, i) => (
                            <OrderLine
                              key={i}
                              item={dev}
                              selectedProducts={editedOrder.genericDevices}
                              totalItems={editedOrder.genericDevices.reduce(
                                (partialSum, a) => partialSum + a.count,
                                0
                              )}
                              setTotalItems={() => {}}
                              setTotalPrice={() => {}}
                              setSelectedProducts={(e, v) => {
                                setEditedOrder({
                                  ...editedOrder,
                                  genericDevices: e,
                                  totalPrice: e.reduce(
                                    (partialSum, a) =>
                                      partialSum +
                                      a.count *
                                        (a.price *
                                          ((100 - (a.discount || 0)) / 100)),
                                    0
                                  ),
                                });
                                // setRefresh(Date.now());
                              }}
                              totalPrice={editedOrder.totalPrice}
                              onlyPrint={!allowItemChanges()}
                              serialNumberAllocation={
                                editedOrder.paymentStatus === "completed" ||
                                editedOrder.paymentStatus === "deposit" ||
                                editedOrder.Devices.length
                                  ? editedOrder.Devices
                                  : undefined
                              }
                              editedOrder={editedOrder}
                              setEditedOrder={setEditedOrder}
                              editable={allowItemChanges()}
                              width={width}
                            />
                          ))}
                        <Grid
                          item
                          container
                          xs={12}
                          style={{ borderTop: "1px solid #000" }}
                        >
                          {width === "xs" ? null : <Grid item xs={2}></Grid>}
                          <Grid item sm={2} xs={3}>
                            <Typography
                              style={{ textAlign: "right", padding: 5 }}
                            >
                              {t("orders.totalItems")}
                            </Typography>
                          </Grid>
                          <Grid item sm={1} xs={3}>
                            <Typography
                              style={{ textAlign: "right", padding: 5 }}
                            >
                              {editedOrder.genericDevices.reduce(
                                (partialSum, a) => partialSum + a.count,
                                0
                              )}
                            </Typography>
                          </Grid>
                          {width === "xs" ? null : <Grid item xs={4}></Grid>}
                          <Grid item sm={1} xs={3}>
                            <Typography
                              style={{ textAlign: "right", padding: 5 }}
                            >
                              {t("orders.totalPrice")}
                            </Typography>
                          </Grid>
                          <Grid item sm={1} xs={3} key={refrs + "" + 1}>
                            <Typography
                              style={{ textAlign: "right", padding: 5 }}
                            >
                              {editedOrder.totalPrice.toFixed(2)}{" "}
                              {editedOrder.currency}
                            </Typography>
                          </Grid>
                          {width === "xs" ? null : <Grid item xs={1}></Grid>}
                        </Grid>
                        <Grid
                          item
                          container
                          xs={12}
                          style={{ borderTop: "1px solid #000" }}
                        >
                          {width === "xs" ? null : <Grid item xs={4}></Grid>}
                          <Grid item sm={1} xs={3}>
                            <Typography
                              style={{ textAlign: "right", padding: 5 }}
                            >
                              {t("orders.vat") + "%"}
                            </Typography>
                          </Grid>
                          <Grid item sm={2} xs={3}>
                            <Typography
                              style={{ textAlign: "right", padding: 5 }}
                            >
                              {editedOrder.vat || 0}%
                            </Typography>
                          </Grid>
                          {width === "xs" ? null : <Grid item xs={1}></Grid>}
                          <Grid item sm={2} xs={3} key={refrs + "" + 2}>
                            <Typography
                              style={{ textAlign: "right", padding: 5 }}
                            >
                              {t("orders.totalPrice") + " + " + t("orders.vat")}
                            </Typography>
                          </Grid>
                          <Grid item sm={1} xs={3} key={refrs + "" + 3}>
                            <Typography
                              style={{ textAlign: "right", padding: 5 }}
                            >
                              {(
                                editedOrder.totalPrice *
                                (1 + editedOrder.vat / 100)
                              ).toFixed(2)}{" "}
                              {editedOrder.currency}
                            </Typography>
                          </Grid>
                          {width === "xs" ? null : <Grid item xs={1}></Grid>}
                        </Grid>
                      </>
                    ),
                  },
                  {
                    permission: [USER_GROUPS.ADMIN, USER_GROUPS.SUPER_USER],
                    label: (
                      <>
                        <AttachFileIcon
                          style={{ fontSize: 20, verticalAlign: "middle" }}
                        />
                        {t("media")}
                      </>
                    ),
                    content: (
                      <MediaFiles
                        resource={{ type: "order", number: editedOrder.number }}
                        listCallback={() => {}}
                      />
                    ),
                  },
                  {
                    permission: [USER_GROUPS.ADMIN, USER_GROUPS.SUPER_USER],
                    label: (
                      <>
                        <ChatIcon />
                        {t("notes")}
                      </>
                    ),
                    content: (
                      <NoteSection
                        parent={{ OrderId: editedOrder.id }}
                        notes={editedOrder.Notes}
                        reload={() => {
                          window.location.reload();
                        }}
                        noPaper={true}
                      />
                    ),
                  },
                ]}
              />
            </Grid>

            {JSON.stringify(selectedOrder.genericDevices) !==
            JSON.stringify(editedOrder.genericDevices) ? (
              <Grid item xs={12} container>
                <Grid item xs={12}>
                  <Typography>{t("orders.invoiceLanguage")}</Typography>
                </Grid>

                <TextField
                  select
                  defaultValue={i18n.language}
                  onChange={(e, v) => {
                    setInvoiceLang(e.target.value);
                  }}
                  variant="outlined"
                >
                  <MenuItem value="en" key={"en"} label="English">
                    English
                  </MenuItem>
                  <MenuItem value="fi" key={"fi"} label="Suomi">
                    Suomi
                  </MenuItem>
                </TextField>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Button
                variant="outlined"
                onClick={() => {
                  if (editable) {
                    setEditedOrder(JSON.parse(JSON.stringify(selectedOrder)));
                  }
                  setEditable(!editable);
                  setEditDetails(false);
                  setEditShipping(false);
                }}
              >
                {!editable ? t("edit") : t("cancel")}
              </Button>
              {editable ? (
                <>
                  {JSON.stringify(selectedOrder.genericDevices) !==
                  JSON.stringify(editedOrder.genericDevices) ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setShowPreview(true);
                      }}
                    >
                      {t("preview")}
                    </Button>
                  ) : null}
                  <Button
                    variant="contained"
                    disabled={
                      (selectedOrder.Devices.length ===
                        editedOrder.Devices.length &&
                        JSON.stringify(editedOrder) ===
                          JSON.stringify(selectedOrder)) ||
                      !editedOrder.genericDevices.length ||
                      !editedOrder.genericDevices.reduce(
                        (partialSum, a) => partialSum + a.count,
                        0
                      )
                    }
                    color="primary"
                    onClick={editOrder}
                  >
                    {t("save")}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setShowDialog(true);
                    }}
                  >
                    {t("delete")}
                  </Button>
                </>
              ) : null}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {/* Preview needs to be outside the Grid otherwise the PDF print does not zoom correctly */}
      <InvoicePreviewWindow
        show={showPreview}
        submit={submit}
        setSubmit={setSubmit}
        setShow={setShowPreview}
        selectedProducts={editedOrder.genericDevices}
        totalItems={editedOrder.genericDevices.reduce(
          (partialSum, a) => partialSum + a.count,
          0
        )}
        totalPrice={editedOrder.totalPrice}
        currency={editedOrder.currency}
        clientInfoObject={editedOrder.clientInfo}
        shippingInfo={editedOrder.shippingInfo}
        issuer={editedOrder.invoiceIssuer}
        invoiceLang={invoiceLang}
        saveContact={false}
        width={width}
        vat={editedOrder.vat}
        details={editedOrder.details}
        orderNumber={editedOrder.number}
        editedOrder={editedOrder}
      />
    </>
  ) : null;
}

export default withWidth()(EditOrder);
