import { IconButton, Tooltip, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import DoneOutlined from "@material-ui/icons/CheckCircle";
import EditIcon from "@material-ui/icons/Edit";
import EditableTypography from "../../Components/EditableTypography";
// import OrderLine from "./OrderLine";

export default function ShippingAddress({
  editable,
  setShipmentData,
  shipmentData,
  header,
  customSetter,
}) {
  const [_originalOrder, setOriginalEditedOrder] = useState({
    ...shipmentData,
  });
  const { t } = useTranslation();
  const [editShipping, setEditShipping] = useState(false);

  const submitEditedOrder = () => {
    if (
      !shipmentData ||
      JSON.stringify(_originalOrder) === JSON.stringify(shipmentData)
    ) {
      return;
    }
    setShipmentData({
      ...shipmentData,
      shippingInfo: shipmentData.shippingInfo,
    });
    setEditShipping(false);
  };
  const { companyName } =
    (shipmentData.shippingInfo?.pickupAddress
      ? shipmentData.shippingInfo.pickupAddress
      : shipmentData.shippingInfo) || shipmentData.clientInfo;
  const { companyAddress } =
    (shipmentData.shippingInfo?.pickupAddress
      ? shipmentData.shippingInfo.pickupAddress
      : shipmentData.shippingInfo) || shipmentData.clientInfo;
  const { companyVatId } =
    (shipmentData.shippingInfo?.pickupAddress
      ? shipmentData.shippingInfo.pickupAddress
      : shipmentData.shippingInfo) || shipmentData.clientInfo;
  const { contactName } =
    (shipmentData.shippingInfo?.pickupAddress
      ? shipmentData.shippingInfo.pickupAddress
      : shipmentData.shippingInfo) || shipmentData.clientInfo;
  const { contactPhone } =
    (shipmentData.shippingInfo?.pickupAddress
      ? shipmentData.shippingInfo.pickupAddress
      : shipmentData.shippingInfo) || shipmentData.clientInfo;
  const { contactEmail } =
    (shipmentData.shippingInfo?.pickupAddress
      ? shipmentData.shippingInfo.pickupAddress
      : shipmentData.shippingInfo) || shipmentData.clientInfo;

  useEffect(() => {
    if (!editable && editShipping) {
      setEditShipping(!editShipping);
      setShipmentData({ ..._originalOrder });
    }
  }, [editable, editShipping]);

  return (
    <>
      <Typography style={{ marginTop: 20, fontWeight: "bold" }}>
        {header || t("orders.shippingInfo")}
        {editable ? (
          <Tooltip title={`${t("edit")}`}>
            <IconButton
              onClick={() => {
                setEditShipping(!editShipping);
                setShipmentData({ ..._originalOrder });
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        ) : null}
        {JSON.stringify(shipmentData) !== JSON.stringify(_originalOrder) &&
        editShipping ? (
          <IconButton
            variant="contained"
            color="primary"
            onClick={submitEditedOrder}
          >
            <DoneOutlined />
          </IconButton>
        ) : null}
      </Typography>
      <EditableTypography
        edit={editShipping}
        onChange={(e, v) => {
          if (customSetter) {
            customSetter(e, v, "companyName");
            return;
          }
          setShipmentData({
            ...shipmentData,
            shippingInfo: {
              ...shipmentData.shippingInfo,
              companyName: e.target.value,
            },
          });
        }}
        name={t("contactInfo.companyName")}
      >
        {companyName}
      </EditableTypography>
      <EditableTypography
        edit={editShipping}
        onChange={(e, v) => {
          if (customSetter) {
            customSetter(e, v, "companyAddress");
            return;
          }
          setShipmentData({
            ...shipmentData,
            shippingInfo: {
              ...shipmentData.shippingInfo,
              companyAddress: e.target.value,
            },
          });
        }}
        name={t("contactInfo.companyAddress")}
        multiline
      >
        {companyAddress}
      </EditableTypography>
      <EditableTypography
        edit={editShipping}
        prefix={"VAT ID:"}
        onChange={(e, v) => {
          if (customSetter) {
            customSetter(e, v, "companyVatId");
            return;
          }
          setShipmentData({
            ...shipmentData,
            shippingInfo: {
              ...shipmentData.shippingInfo,
              companyVatId: e.target.value,
            },
          });
        }}
      >
        {companyVatId}
      </EditableTypography>
      <EditableTypography
        edit={editShipping}
        onChange={(e, v) => {
          if (customSetter) {
            customSetter(e, v, "contactName");
            return;
          }
          setShipmentData({
            ...shipmentData,
            shippingInfo: {
              ...shipmentData.shippingInfo,
              contactName: e.target.value,
            },
          });
        }}
        name={t("contactInfo.contactName")}
      >
        {contactName}
      </EditableTypography>
      <EditableTypography
        edit={editShipping}
        onChange={(e, v) => {
          if (customSetter) {
            customSetter(e, v, "contactPhone");
            return;
          }
          setShipmentData({
            ...shipmentData,
            shippingInfo: {
              ...shipmentData.shippingInfo,
              contactPhone: e.target.value,
            },
          });
        }}
        name={t("contactInfo.contactPhone")}
      >
        {contactPhone}
      </EditableTypography>
      <EditableTypography
        edit={editShipping}
        onChange={(e, v) => {
          if (customSetter) {
            customSetter(e, v, "contactEmail");
            return;
          }
          setShipmentData({
            ...shipmentData,
            shippingInfo: {
              ...shipmentData.shippingInfo,
              contactEmail: e.target.value,
            },
          });
        }}
        name={t("contactInfo.contactEmail")}
      >
        {contactEmail}
      </EditableTypography>
    </>
  );
}
