import { Dialog, DialogContent, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CheckCircle from "@material-ui/icons/CheckCircle";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import BrowseContactsDialog from "../../Components/Contacts/BrowseContactsDialog";
import ContactInfoBadge from "../../Components/Contacts/ContactInfoBadge";
import ControlledSelect from "../../Components/ControlledSelect";
import DialogAppBar from "../../Components/DialogAppBar";
import {
  deviceProfiles,
  getDeviceType,
  getLogoImage,
  getToken,
  lifeCycleStates,
  requestErrorHandler,
  warehouseLocation,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { showPopupSnackbar } from "../../redux/actions/snackbarActions";
const server = EnvSettings.server;

export default function AddDeviceDialog({ open, exit }) {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState();
  const [errorState, setErrorState] = useState();
  const [lcWarehouse, setLCWarehouse] = useState();
  const [lcClient, setLCClient] = useState();
  const [lcState, setLCState] = useState();
  const [note, setNote] = useState();
  const dispatch = useDispatch();

  const compareSerial = (val, data, format) => {
    if (!data || !val) {
      if (format === "bool") {
        return false;
      }
      return null;
    }
    if (
      val.length === data.serialNumberFormat.length &&
      val.match(data.serialNumberFormat.regex)
    ) {
      if (format === "bool") {
        return false;
      }
      return (
        <Typography style={{ color: "green" }}>
          {t("newDeviceDialog.validSerialNumber")}{" "}
          <CheckCircle style={{ verticalAlign: "middle" }} />
        </Typography>
      );
    }
    if (format === "bool") {
      return true;
    }
    return (
      <Typography color="secondary">
        {t("newDeviceDialog.serialNumberFormat")}:{" "}
        {[...Array(data.serialNumberFormat.length)]
          .map((a, i) => (i + 1 <= data.prefix.length ? data.prefix[i] : "X"))
          .join("")}
      </Typography>
    );
  };

  const submitTransfer = () => {
    if (
      compareSerial(
        searchValue,
        deviceProfiles.find((f) => f.name === getDeviceType(searchValue)),
        "bool"
      )
    ) {
      return;
    }
    var transferData = {
      newDevice: searchValue,
      state: lcState,
      location:
        lcState === "dispatched" && !lcWarehouse ? "client" : lcWarehouse,
    };
    if (!transferData.state) {
      alert(t("newDeviceDialog.fillRequired"));
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        transferData: transferData,
        noteText: note,
        ...(lcClient ? { clientInfo: lcClient } : {}),
      }),
    };
    fetch(server + "/add_transfer", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        // Reload on success
        if (!result.error) {
          dispatch(showPopupSnackbar(result));
          setTimeout(() => {
            window.location.reload();
          }, 1200);
        } else if (result.message === "ER_DUP_ENTRY") {
          setErrorState({
            message: t("newDeviceDialog.serialNumberExists"),
            serialNumber: searchValue,
          });
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return open ? (
    <Dialog open={open} onClose={exit} fullScreen>
      <DialogContent>
        <DialogAppBar header={t("newDeviceDialog.header")} onClose={exit} />
        <Grid
          container
          spacing={3}
          style={{
            marginTop: 60,
            marginBottom: 20,
            paddingBottom: 30,
            position: "relative",
          }}
        >
          <Grid item container xs={12}>
            {deviceProfiles.map((m, i) => (
              <Grid item style={{ margin: 5 }} key={i}>
                <Button
                  onClick={() => {
                    setSearchValue(m.prefix);
                  }}
                  variant="outlined"
                >
                  {m.name}
                </Button>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} sm={3}>
            {searchValue ? (
              <>
                <img
                  src={getLogoImage(searchValue)}
                  style={{ maxWidth: 100, height: 100 }}
                ></img>
                <Typography>
                  {
                    deviceProfiles.find(
                      (f) => f.name === getDeviceType(searchValue)
                    )?.name
                  }
                </Typography>
              </>
            ) : (
              <Typography>{t("newDeviceDialog.startTyping")}</Typography>
            )}
          </Grid>
          <Grid item container spacing={1} xs={12} sm={9}>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                fullWidth
                label={t("serialNumber")}
                value={searchValue}
                InputLabelProps={{ shrink: !!searchValue ? true : undefined }}
                onChange={(e) => {
                  setSearchValue(e.target.value.toUpperCase());
                }}
              ></TextField>
              {searchValue && getDeviceType(searchValue)
                ? compareSerial(
                    searchValue,
                    deviceProfiles.find(
                      (f) => f.name === getDeviceType(searchValue)
                    )
                  )
                : null}
              {errorState ? (
                <Typography color="secondary">
                  {errorState.serialNumber} : {errorState.message}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={3}>
              <ControlledSelect
                itemlist={warehouseLocation}
                label={t("deviceList.lcLocation")}
                onChange={(e, v) => {
                  if (e) {
                    setLCWarehouse(e.target.value);
                    return;
                  }
                  setLCWarehouse();
                }}
                deleteButton={true}
              />
            </Grid>
            <Grid item container xs={12} sm={3}>
              <ControlledSelect
                itemlist={lifeCycleStates}
                label={t("deviceList.lcState")}
                onChange={(e, v) => {
                  if (e) {
                    setLCState(e.target.value);
                    return;
                  }
                  setLCState();
                }}
                deleteButton={true}
              />
            </Grid>
            <Grid item container xs={12} sm={3}>
              <BrowseContactsDialog
                activeObject={
                  lcClient && Object.values(lcClient).find((v) => v !== "")
                    ? lcClient
                    : undefined
                }
                setClientInfoObject={setLCClient}
              />
              {lcClient ? (
                <ContactInfoBadge
                  clientInfoObject={lcClient}
                  clearClientInfo={() => {
                    setLCClient();
                  }}
                />
              ) : null}
            </Grid>
            <Grid item container xs={12} sm={3}>
              <TextField
                fullWidth
                label={t("note")}
                variant="outlined"
                onChange={(e) => {
                  if (e.target.value) {
                    setNote(e.target.value);
                    return;
                  }
                  setNote();
                }}
              ></TextField>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              disabled={compareSerial(
                searchValue,
                deviceProfiles.find(
                  (f) => f.name === getDeviceType(searchValue)
                ),
                "bool"
              )}
              onClick={() => {
                submitTransfer();
              }}
            >
              {t("add")}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  ) : null;
}
