import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  withWidth,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Title from "../../../Components/Title";

import StockItemSearch from "../../../Components/StockItems/StockItemSearch";
import {
  shipmentStatus,
  shippingMethods,
  warehouseLocation,
} from "../../../Utils/Common";
import OrderLine from "../../ResupplyOrders/OrderLine";
import ShippingAddress from "../ShippingAddress";
// import ShippingItemLine from "./ShippingItemLine";
/**
 * Delivery to
 * Items
 * External Ref
 * Order
 * Term
 * Shipping Company
 * Status
 * Pickup
 * ETA
 * Tracking number
 */

function Outward({ setShipmentData, shipmentData }) {
  const { t } = useTranslation();
  return (
    <>
      <Grid item xs={6} sm={4}>
        <ShippingAddress
          editable={true}
          setShipmentData={setShipmentData}
          shipmentData={shipmentData}
        />
      </Grid>
      <Grid container direction="column" item sm={4} justify="space-between">
        {["shippingCompany", "externalRef", "shippingTerm"].map((m, i) => (
          <TextField
            key={m + i}
            label={t("shipments." + m)}
            variant="outlined"
            fullWidth
            onChange={(e, v) => {
              if (!e || !e.target) {
                return;
              }
              var obj = { ...shipmentData, [m]: e.target.value };
              setShipmentData({ ...obj });
            }}
          />
        ))}
        <TextField
          label={t("shipments.pickupAddress")}
          defaultValue={"mailhouse"}
          variant="outlined"
          onChange={(e, v) => {
            if (!e || !e.target.value) {
              return;
            }
            var obj = { ...shipmentData, pickupAddress: e.target.value };
            setShipmentData({ ...obj });
          }}
          fullWidth
          select
        >
          {warehouseLocation.map((m, i) => (
            <MenuItem key={m.value + i} value={m.value}>
              {m.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label={t("shipments.shippingMethod")}
          variant="outlined"
          fullWidth
          select
          onChange={(e, v) => {
            if (!e || !e.target.value) {
              return;
            }
            var obj = { ...shipmentData, shippingMethod: e.target.value };
            setShipmentData({ ...obj });
          }}
        >
          {shippingMethods.map((s, i) => (
            <MenuItem value={s.value} key={i}>
              {t("shippingMethods." + s.value)}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid
        container
        direction="column"
        item
        sm={4}
        justify="space-between"
        style={{ minHeight: 325 }}
      >
        {["pickupDate", "eta"].map((m, i) => (
          <TextField
            key={m + i}
            label={t("shipments." + m)}
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type={"date"}
            onChange={(e, v) => {
              if (!e || !e.target) {
                return;
              }
              var obj = { ...shipmentData, [m]: e.target.value };
              setShipmentData({ ...obj });
            }}
          />
        ))}
        <TextField
          label={t("shipments.trackingNumber")}
          variant="outlined"
          fullWidth
          onChange={(e, v) => {
            if (!e || !e.target) {
              return;
            }
            var obj = { ...shipmentData, trackingNumber: e.target.value };
            setShipmentData({ ...obj });
          }}
        />
        <TextField
          label={t("shipments.status")}
          variant="outlined"
          fullWidth
          defaultValue={"waiting"}
          select
          onChange={(e, v) => {
            if (!e || !e.target) {
              return;
            }
            var obj = { ...shipmentData, status: e.target.value };
            setShipmentData({ ...obj });
          }}
        >
          {shipmentStatus.map((p, i) => (
            <MenuItem value={p.value} key={i}>
              {t("shipmentStates." + p.value)}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label={t("shipments.totalPrice")}
          type="number"
          value={shipmentData.totalPrice}
          variant="outlined"
          fullWidth
          onChange={(e, v) => {
            if (!e || !e.target) {
              return;
            }
            var obj = { ...shipmentData, totalPrice: e.target.value };
            setShipmentData({ ...obj });
          }}
        />
      </Grid>
    </>
  );
}

function Inward({ setShipmentData, shipmentData }) {
  const { t } = useTranslation();
  return (
    <>
      <Grid item xs={6} sm={4}>
        <ShippingAddress
          editable={true}
          setShipmentData={() => {}}
          shipmentData={shipmentData}
          header={t("shipments.pickupAddress")}
          customSetter={(e, v, name) => {
            setShipmentData({
              ...shipmentData,
              shippingInfo: {
                ...shipmentData.shippingInfo,
                pickupAddress: {
                  ...shipmentData.shippingInfo.pickupAddress,
                  [name]: e.target.value,
                },
              },
            });
          }}
        />
      </Grid>
      <Grid container direction="column" item sm={4} justify="space-between">
        {["shippingCompany", "externalRef", "shippingTerm"].map((m, i) => (
          <TextField
            key={m + i}
            label={t("shipments." + m)}
            variant="outlined"
            fullWidth
            onChange={(e, v) => {
              if (!e || !e.target) {
                return;
              }
              var obj = { ...shipmentData, [m]: e.target.value };
              setShipmentData({ ...obj });
            }}
          />
        ))}
        <TextField
          label={t("shipments.deliveryTo")}
          defaultValue={"mailhouse"}
          variant="outlined"
          onChange={(e, v) => {
            if (!e || !e.target.value) {
              return;
            }
            var obj = {
              ...shipmentData,
              shippingInfo: {
                ...shipmentData.shippingInfo,
                companyName: e.target.value,
              },
            };
            setShipmentData({ ...obj });
          }}
          fullWidth
          select
        >
          {warehouseLocation.map((m, i) => (
            <MenuItem key={m.value + i} value={m.value}>
              {m.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label={t("shipments.shippingMethod")}
          variant="outlined"
          fullWidth
          select
          onChange={(e, v) => {
            if (!e || !e.target.value) {
              return;
            }
            var obj = { ...shipmentData, shippingMethod: e.target.value };
            setShipmentData({ ...obj });
          }}
        >
          {shippingMethods.map((s, i) => (
            <MenuItem value={s.value} key={i}>
              {t("shippingMethods." + s.value)}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid
        container
        direction="column"
        item
        sm={4}
        justify="space-between"
        style={{ minHeight: 325 }}
      >
        {["pickupDate", "eta"].map((m, i) => (
          <TextField
            key={m + i}
            label={t("shipments." + m)}
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type={"date"}
            onChange={(e, v) => {
              if (!e || !e.target) {
                return;
              }
              var obj = { ...shipmentData, [m]: e.target.value };
              setShipmentData({ ...obj });
            }}
          />
        ))}
        <TextField
          label={t("shipments.trackingNumber")}
          variant="outlined"
          fullWidth
          onChange={(e, v) => {
            if (!e || !e.target) {
              return;
            }
            var obj = { ...shipmentData, trackingNumber: e.target.value };
            setShipmentData({ ...obj });
          }}
        />
        <TextField
          label={t("shipments.status")}
          variant="outlined"
          fullWidth
          defaultValue={"waiting"}
          select
          onChange={(e, v) => {
            if (!e || !e.target) {
              return;
            }
            var obj = { ...shipmentData, status: e.target.value };
            setShipmentData({ ...obj });
          }}
        >
          {shipmentStatus.map((p, i) => (
            <MenuItem value={p.value} key={i}>
              {t("shipmentStates." + p.value)}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label={t("shipments.totalPrice")}
          type="number"
          value={shipmentData.totalPrice}
          variant="outlined"
          fullWidth
          onChange={(e, v) => {
            if (!e || !e.target) {
              return;
            }
            var obj = { ...shipmentData, totalPrice: e.target.value };
            setShipmentData({ ...obj });
          }}
        />
      </Grid>
    </>
  );
}

function Between({ setShipmentData, shipmentData }) {
  const { t } = useTranslation();
  return (
    <>
      <Grid item xs={6} sm={4}>
        <>
          <TextField
            label={t("shipments.pickupAddress")}
            defaultValue={"mailhouse"}
            variant="outlined"
            onChange={(e, v) => {
              if (!e || !e.target.value) {
                return;
              }
              var obj = { ...shipmentData, pickupAddress: e.target.value };
              setShipmentData({ ...obj });
            }}
            fullWidth
            select
          >
            {warehouseLocation.map((m, i) => (
              <MenuItem key={m.value + i} value={m.value}>
                {m.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            style={{ marginTop: 10 }}
            label={t("shipments.deliveryTo")}
            defaultValue={"mailhouse"}
            variant="outlined"
            onChange={(e, v) => {
              if (!e || !e.target.value) {
                return;
              }
              var obj = {
                ...shipmentData,
                shippingInfo: { companyName: e.target.value },
              };
              setShipmentData({ ...obj });
            }}
            fullWidth
            select
          >
            {warehouseLocation.map((m, i) => (
              <MenuItem key={m.value + i} value={m.value}>
                {m.label}
              </MenuItem>
            ))}
          </TextField>
        </>
      </Grid>
      <Grid container direction="column" item sm={4} justify="space-between">
        {["shippingCompany", "externalRef", "shippingTerm"].map((m, i) => (
          <TextField
            key={m + i}
            label={t("shipments." + m)}
            variant="outlined"
            fullWidth
            onChange={(e, v) => {
              if (!e || !e.target) {
                return;
              }
              var obj = { ...shipmentData, [m]: e.target.value };
              setShipmentData({ ...obj });
            }}
          />
        ))}
        <TextField
          label={t("shipments.shippingMethod")}
          variant="outlined"
          fullWidth
          select
          onChange={(e, v) => {
            if (!e || !e.target.value) {
              return;
            }
            var obj = { ...shipmentData, shippingMethod: e.target.value };
            setShipmentData({ ...obj });
          }}
        >
          {shippingMethods.map((s, i) => (
            <MenuItem value={s.value} key={i}>
              {t("shippingMethods." + s.value)}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid
        container
        direction="column"
        item
        sm={4}
        justify="space-between"
        style={{ minHeight: 325 }}
      >
        {["pickupDate", "eta"].map((m, i) => (
          <TextField
            key={m + i}
            label={t("shipments." + m)}
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type={"date"}
            onChange={(e, v) => {
              if (!e || !e.target) {
                return;
              }
              var obj = { ...shipmentData, [m]: e.target.value };
              setShipmentData({ ...obj });
            }}
          />
        ))}
        <TextField
          label={t("shipments.trackingNumber")}
          variant="outlined"
          fullWidth
          onChange={(e, v) => {
            if (!e || !e.target) {
              return;
            }
            var obj = { ...shipmentData, trackingNumber: e.target.value };
            setShipmentData({ ...obj });
          }}
        />
        <TextField
          label={t("shipments.status")}
          variant="outlined"
          fullWidth
          defaultValue={"waiting"}
          select
          onChange={(e, v) => {
            if (!e || !e.target) {
              return;
            }
            var obj = { ...shipmentData, status: e.target.value };
            setShipmentData({ ...obj });
          }}
        >
          {shipmentStatus.map((p, i) => (
            <MenuItem value={p.value} key={i}>
              {t("shipmentStates." + p.value)}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label={t("shipments.totalPrice")}
          type="number"
          value={shipmentData.totalPrice}
          variant="outlined"
          fullWidth
          onChange={(e, v) => {
            if (!e || !e.target) {
              return;
            }
            var obj = { ...shipmentData, totalPrice: e.target.value };
            setShipmentData({ ...obj });
          }}
        />
      </Grid>
    </>
  );
}

function OtherShipment({ width, shipmentData, setShipmentData }) {
  const { t } = useTranslation();
  const [productFilter, setProductFilter] = useState(false);
  const [newProduct, setNewProduct] = useState(false);
  const [newProductCount, setNewProductCount] = useState();
  const [newProductSku, setNewProductSku] = useState();
  const [newProductName, setNewProductName] = useState();
  const [gridRef, setGridRef] = useState("ssdafasfasd");

  const handleRadio = (e, v) => {
    var obj = { ...shipmentData, shipmentDirection: v };
    if (v === "inward") {
      obj.pickupAddress = undefined;
      obj.shippingInfo = { companyName: "mailhouse" };
    }
    if (v === "outward") {
      obj.shippingInfo = {};
    }
    setShipmentData(obj);
  };

  useEffect(() => {
    if (!shipmentData) {
      setShipmentData({
        shippingInfo: {},
        shipmentDirection: "outward",
        status: "waiting",
      });
    }
  }, []);

  return shipmentData ? (
    <>
      <Grid item xs={12}>
        <Title>{t("shipments.forOther")}</Title>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            {t("shipments.shipmentDirection")}
          </FormLabel>
          <RadioGroup
            row
            aria-label="position"
            name="position"
            defaultValue="outward"
            onChange={handleRadio}
          >
            <FormControlLabel
              value="outward"
              control={<Radio color="primary" />}
              label={t("shipments.outward")}
              labelPlacement="top"
            />
            <FormControlLabel
              value="inward"
              control={<Radio color="primary" />}
              label={t("shipments.inward")}
              labelPlacement="top"
            />
            <FormControlLabel
              value="between"
              control={<Radio color="primary" />}
              label={t("shipments.between")}
              labelPlacement="top"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <>
        {shipmentData.shipmentDirection === "outward" ? (
          <Outward
            shipmentData={shipmentData}
            setShipmentData={setShipmentData}
          />
        ) : shipmentData.shipmentDirection === "inward" ? (
          <Inward
            shipmentData={shipmentData}
            setShipmentData={setShipmentData}
          />
        ) : (
          <Between
            shipmentData={shipmentData}
            setShipmentData={setShipmentData}
          />
        )}
        <Grid item container xs={12} spacing={width === "xs" ? "" : 3}>
          <Grid item xs={12}>
            <Title>{t("orders.itemSearch")}</Title>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              defaultChecked={false}
              control={
                <Checkbox
                  onChange={(e, v) => {
                    setProductFilter(!productFilter);
                  }}
                  defaultChecked={false}
                  color="primary"
                />
              }
              label={t("orders.showOnlyDevices")}
            />
          </Grid>
          <Grid item xs={6}>
            <StockItemSearch
              allowNewItems={false}
              onChange={(e, v) => {
                if (v && v.sku) {
                  setShipmentData({
                    ...shipmentData,
                    genericDevices: [
                      ...(shipmentData.genericDevices || []),
                      { ...v, count: 1 },
                    ],
                  });
                }
              }}
              clearOnSelect={true}
              includeQuantity={true}
              includeTags={true}
              includePacking={true}
              placeholder={t("orders.startSearchingProducts")}
              filters={
                productFilter
                  ? {
                      Tags: [
                        "eCabin",
                        "CryoCabin",
                        "XCryo",
                        "XTone",
                        "OxyPro",
                        "LedPro",
                        "XGun",
                      ],
                    }
                  : undefined
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setNewProduct(true);
              }}
            >
              {t("resupplyOrders.itemNotInList")}
            </Button>
          </Grid>
          {shipmentData?.genericDevices?.length || newProduct ? (
            <>
              {width === "xs" ? null : (
                <Grid
                  container
                  style={{ borderTop: "1px solid #000", padding: 5 }}
                >
                  {width === "xs" ? null : <Grid item sm={1} xs={1}></Grid>}
                  <Grid item sm={3} xs={3} style={{ padding: 5 }}>
                    SKU
                  </Grid>
                  {width === "xs" ? null : (
                    <Grid item sm={3} xs={3} style={{ padding: 5 }}>
                      {t("description")}
                    </Grid>
                  )}
                  <Grid item sm={1} xs={3} style={{ padding: 5 }}>
                    {t("orders.numberItems")}
                  </Grid>
                  {width === "xs" ? null : <Grid item sm={1} xs={1}></Grid>}
                </Grid>
              )}
              {newProduct ? (
                <Grid container key={gridRef}>
                  <Grid item xs={3} style={{ padding: 5 }}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      size="small"
                      label="SKU"
                      onChange={(e, v) => {
                        if (!e || !e.target.value) {
                          return;
                        }
                        setNewProductSku(e.target.value);
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={3} style={{ padding: 5 }}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      size="small"
                      label="Name"
                      onChange={(e, v) => {
                        if (!e || !e.target.value) {
                          return;
                        }
                        setNewProductName(e.target.value);
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={1} style={{ padding: 5 }}>
                    <TextField
                      variant="outlined"
                      type="number"
                      inputProps={{ min: 1 }}
                      label={t("count")}
                      size="small"
                      onChange={(e, v) => {
                        if (!e || !e.target.value) {
                          return;
                        }
                        setNewProductCount(Number(e.target.value));
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={2} style={{ padding: 5 }}>
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={() => {
                        if (!newProductSku && !newProductName) {
                          return;
                        }
                        setShipmentData({
                          ...shipmentData,
                          genericDevices: [
                            ...(shipmentData?.genericDevices || []),
                            {
                              sku: newProductSku,
                              name: newProductName,
                              count: newProductCount,
                              id: "new" + Math.round(Math.random() * 100),
                            },
                          ],
                        });
                        setNewProductCount();
                        setNewProductName();
                        setNewProductSku();
                        setGridRef(Math.random());
                      }}
                    >
                      OK
                    </Button>
                  </Grid>
                  <Grid item xs={1} style={{ padding: 5 }}>
                    <Button
                      color="secondary"
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setNewProduct(false);
                      }}
                    >
                      {t("cancel")}
                    </Button>
                  </Grid>
                </Grid>
              ) : null}
              {shipmentData?.genericDevices?.map((dev, i) => (
                <OrderLine
                  key={i}
                  item={dev}
                  selectedProducts={shipmentData.genericDevices}
                  setSelectedProducts={(data) => {
                    setShipmentData({
                      ...shipmentData,
                      genericDevices: [...data],
                    });
                  }}
                  totalPrice={0}
                  setTotalPrice={() => {}}
                  width={width}
                />
              ))}
            </>
          ) : null}
          <Grid item xs={12}>
            <TextField
              fullWidth
              rows={2}
              multiline
              label={t("details")}
              variant="outlined"
              onChange={(e, v) => {
                if (!e || !e.target) {
                  return;
                }
                var obj = { ...shipmentData, details: e.target.value };
                setShipmentData({ ...obj });
              }}
            />
          </Grid>
        </Grid>
      </>
    </>
  ) : null;
}

export default withWidth()(OtherShipment);
