import { IconButton, MenuItem, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Clear from "@material-ui/icons/Clear";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ShipmentSearchAutocomplete from "../../../../Components/ShipmentSearchAutocomplete";
import Title from "../../../../Components/Title";

export default function ShipmentsSection({
  unedited,
  emaillist,
  setEmailList,
}) {
  unedited = unedited || emaillist;
  const { t } = useTranslation();
  const [resetKey, setResetKey] = useState("sdfasfsda");

  return (
    <Grid item sm={3} xs={12}>
      <Title>{t("emailLists.shipments")}</Title>
      {unedited &&
        unedited.EmailListShipments &&
        unedited.EmailListShipments.map((u, i) =>
          u && u.Shipment ? (
            <MenuItem
              key={i}
              style={
                emaillist.EmailListShipments.find(
                  (uu) => uu.Shipment?.number === u.Shipment?.number
                )
                  ? {}
                  : { textDecoration: "line-through" }
              }
            >
              <Grid container direction="column">
                <Grid item>
                  <Typography style={{ fontSize: 15 }}>
                    {u.Shipment.number}
                  </Typography>
                </Grid>
                <Grid item container direction="row">
                  <Typography style={{ fontSize: 12 }}>
                    {u.Shipment.shippingInfo.companyName}
                  </Typography>
                  <Typography style={{ fontSize: 12, whiteSpace: "pre-wrap" }}>
                    {" "}
                    {u.Shipment.shippingInfo.location}
                  </Typography>
                  <Typography style={{ fontSize: 12, whiteSpace: "pre-wrap" }}>
                    {" "}
                    {u.Shipment.totalPrice} {u.Shipment.currency}
                  </Typography>
                </Grid>
              </Grid>
              <IconButton
                style={{ position: "absolute", right: 0 }}
                onClick={() => {
                  setEmailList({
                    ...emaillist,
                    EmailListShipments: emaillist.EmailListShipments.filter(
                      (f) => f.Shipment?.id !== u.Shipment?.id
                    ),
                  });
                }}
              >
                <Clear />
              </IconButton>
            </MenuItem>
          ) : null
        )}
      {emaillist &&
        unedited &&
        emaillist.EmailListShipments &&
        emaillist.EmailListShipments.filter(
          (u) =>
            !unedited.EmailListShipments.length ||
            !unedited.EmailListShipments.find(
              (f) => u.Shipment?.id === f.Shipment?.id
            )
        ).map((u, i) => (
          <MenuItem
            key={i}
            style={{
              backgroundColor: "rgb(177 255 184)",
              fontSize: 15,
            }}
          >
            <Grid container direction="column">
              <Grid item>
                <Typography style={{ fontSize: 15 }}>
                  {u.Shipment.number}
                </Typography>
              </Grid>
              <Grid item container direction="row">
                <Typography style={{ fontSize: 12 }}>
                  {u.Shipment.shippingInfo.companyName}
                </Typography>
                <Typography style={{ fontSize: 12, whiteSpace: "pre-wrap" }}>
                  {" "}
                  {u.Shipment.shippingInfo.location}
                </Typography>
                <Typography style={{ fontSize: 12, whiteSpace: "pre-wrap" }}>
                  {" "}
                  {u.Shipment.totalPrice} {u.Shipment.currency}
                </Typography>
              </Grid>
            </Grid>
            <IconButton
              style={{ position: "absolute", right: 0 }}
              onClick={() => {
                setEmailList({
                  ...emaillist,
                  EmailListShipments: emaillist.EmailListShipments.filter(
                    (f) => f.Shipment?.id !== u.Shipment?.id
                  ),
                });
              }}
            >
              <Clear />
            </IconButton>
          </MenuItem>
        ))}

      <ShipmentSearchAutocomplete
        style={{ marginTop: 5 }}
        key={resetKey}
        additionalDataFields={[
          "shippingInfo.companyName",
          "shippingInfo.location",
          "totalPrice",
          "currency",
        ]}
        label={t("emailLists.searchShipment")}
        onChange={(e, v) => {
          if (!v || !v.number) {
            return;
          }
          setEmailList({
            ...emaillist,
            EmailListShipments: [
              ...(emaillist.EmailListShipments || []),
              { Shipment: v },
            ],
          });
          setResetKey(new Date().toISOString());
        }}
      />
    </Grid>
  );
}
