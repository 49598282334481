import Grid from "@material-ui/core/Grid";
import React from "react";
import { useTranslation } from "react-i18next";

import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import Build from "@material-ui/icons/Build";
import NotificationsIcon from "@material-ui/icons/Notifications";
import TimelineIcon from "@material-ui/icons/Timeline";
import BadgeLink from "../../../Components/BadgeLink";
import Title from "../../../Components/Title";
import { deviceProfiles, getUser, USER_GROUPS } from "../../../Utils/Common";

export default function Releases() {
  const { t } = useTranslation();
  const badgeLinks = [
    {
      href: "/cpanel/software/releases/ecabin",
      title: "eCabin",
      icon: Build,
      superuser: true,
      image: (
        <img
          alt=""
          style={{ width: 50 }}
          src={
            deviceProfiles.find(
              (f) =>
                f.name.replace(/[^a-zA-Z ]/g, "") === "eCabin".toUpperCase()
            ).image
          }
        ></img>
      ),
    },
    {
      href: "/cpanel/software/releases/ecabin",
      title: "LedPro",
      icon: TimelineIcon,
      disabled: true,
      image: (
        <img
          style={{ width: 50 }}
          alt=""
          src={
            deviceProfiles.find(
              (f) =>
                f.name.replace(/[^a-zA-Z ]/g, "") === "ledpro".toUpperCase()
            )?.image
          }
        ></img>
      ),
    },
    {
      href: "/cpanel/software/releases/ecabin",
      title: "OxyPro",
      icon: NotificationsIcon,
      disabled: true,
      image: (
        <img
          alt=""
          style={{ width: 50 }}
          src={
            deviceProfiles.find(
              (f) =>
                f.name.replace(/[^a-zA-Z ]/g, "") === "oxypro".toUpperCase()
            )?.image
          }
        ></img>
      ),
    },
  ];

  return (
    <Grid container justify="center">
      <Grid item xs>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href={"/cpanel/software"}>
            {t("software.header")}
          </Link>
          <Typography>{t("software.releases")}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid container item xs={12} justify="center">
        <Grid item>
          <Title>{t("software.releases")}</Title>
        </Grid>
      </Grid>
      <Grid container spacing={3} justify="center" item md={12} lg={6}>
        {/* All the admin items as badges */}
        {badgeLinks
          .sort((a, b) => {
            return a.title > b.title ? 1 : -1;
          })
          .map((b, i) =>
            b.superuser &&
            getUser().permissionlevel !== USER_GROUPS.SUPER_USER ? null : (
              <BadgeLink
                key={i}
                href={b.href}
                title={b.title}
                image={b.image}
                disabled={b.disabled}
              />
            )
          )}
      </Grid>
    </Grid>
  );
}
