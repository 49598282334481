import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React from "react";
import { useTranslation } from "react-i18next";

import { formatGoogleDriveMediaLink } from "../../../Utils/Common";

export default function ShippingItemLine({
  item,
  onlyPrint,
  width,
  setShipmentData,
  shipmentData,
}) {
  const { t } = useTranslation();
  width = onlyPrint ? "" : width;

  /**
   *
   * @param {Object} ship
   * @returns Array
   */
  const getOriginalItems = (ship) => {
    if (!ship?.Order && !ship?.ResupplyOrder) {
      return [];
    }
    if (ship.Order) {
      return ship.Order.genericDevices;
    }
    return ship.ResupplyOrder.genericDevices;
  };

  return (
    <Grid
      container
      style={{
        borderRadius: 5,
        backgroundColor: "#eeeeee",
        marginTop: 2,
        padding: 5,
      }}
      alignItems="center"
    >
      <Grid item xs={1} style={{ padding: 5 }}>
        <img
          alt="Item"
          src={formatGoogleDriveMediaLink(item.imageUrl)}
          width={50}
        />
      </Grid>
      <Grid item xs={3} style={{ padding: 5 }}>
        <Typography
          style={{ textDecoration: !item.count ? "line-through" : "" }}
        >
          {item.sku}
        </Typography>{" "}
      </Grid>
      <Grid
        item
        sm={3}
        xs={12}
        style={width === "xs" ? { marginBottom: 5 } : { padding: 5 }}
      >
        <Typography
          style={{ textDecoration: !item.count ? "line-through" : "" }}
        >
          {item.name}
        </Typography>
      </Grid>
      <Grid item sm={2} xs={3} container justify="flex-end">
        {onlyPrint ? (
          <Typography
            style={{
              textAlign: "right",
            }}
          >
            {item.count}
          </Typography>
        ) : (
          <TextField
            label={t("count")}
            type="number"
            style={{ marginRight: 50 }}
            key={item.id}
            defaultValue={Number(item.count)}
            inputProps={{
              min: 0,
              max: Number(item.count),
              style: { textAlign: "right" },
            }}
            variant="outlined"
            onChange={(e) => {
              if (e.target && e.target.value) {
                if (
                  Number(e.target.value) > Number(item.count) ||
                  Number(e.target.value) < 0
                ) {
                  alert("Illegal value!");
                  return;
                }
              }
              var idx = shipmentData.genericDevices.findIndex(
                (a) => a.id === item.id
              );
              var obj = shipmentData.genericDevices[idx];
              obj.count = Number(e.target.value);
              var arr = [...shipmentData.genericDevices];
              arr[idx] = obj;
              setShipmentData({ ...shipmentData, genericDevices: [...arr] });
            }}
          />
        )}
      </Grid>
      <Grid item sm={3} xs={4} style={width === "xs" ? {} : { padding: 5 }}>
        <Typography style={{ textAlign: "left" }}>
          /{" "}
          {
            getOriginalItems(shipmentData).find((a) => a.sku === item.sku)
              ?.count
          }
        </Typography>
      </Grid>
    </Grid>
  );
}
