import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Tooltip,
  Typography,
  withWidth,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BrowseContactsDialog from "../../Components/Contacts/BrowseContactsDialog";
import StockItemSearch from "../../Components/StockItems/StockItemSearch";
import Title from "../../Components/Title";

import Done from "@material-ui/icons/CheckBox";
import { useStyles } from "../../Utils/Styles";
import i18n from "../../i18n";
import InvoicePreviewWindow from "./InvoicePreviewWindow";
import OrderLine from "./OrderLine";

function NewOrder({ width }) {
  const classes = useStyles();
  const [showForm, setShowForm] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [clientInfoObject, setClientInfoObject] = useState({});
  const [totalItems, setTotalItems] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [currency, setCurrency] = useState("EUR");
  const [issuer, setIssuer] = useState("finland");
  const [showPreview, setShowPreview] = useState(false);
  const [saveContact, setSaveContact] = useState(false);
  const [invoiceLang, setInvoiceLang] = useState();
  const [productFilter, setProductFilter] = useState(false);
  const [vat, setVat] = useState(0);
  const [details, setDetails] = useState();
  const [differentShippingAddress, setDifferentShippingAddress] =
    useState(false);
  const [shippingInfo, setShippingInfo] = useState();
  const [submit, setSubmit] = useState(false);
  const { t } = useTranslation();
  const [orderNumber, setOrderNumber] = useState();

  const editClientInfo = (value, key) => {
    setClientInfoObject({ ...clientInfoObject, [key]: value });
  };

  const editShippingInfo = (value, key) => {
    setShippingInfo({ ...shippingInfo, [key]: value });
  };

  const resetForm = () => {
    setShowForm(false);
    setSelectedProducts([]);
    setClientInfoObject({});
    setTotalItems(0);
    setTotalPrice(0);
    setCurrency("EUR");
    setIssuer("finland");
    setShowPreview(false);
    setSaveContact(false);
    setInvoiceLang();
    setDifferentShippingAddress(false);
    setShippingInfo();
    setVat(0);
    // setOrderNumber();
    setSubmit(false);
  };

  const submitOrder = () => {
    setSubmit(true);
    setShowPreview(true);
  };

  useEffect(() => {
    if (clientInfoObject && JSON.stringify(clientInfoObject) !== "{}") {
      setIssuer(
        clientInfoObject.location === "Finland" ? "finland" : "estonia"
      );
    }
  }, [clientInfoObject]);

  useState(() => {
    setOrderNumber(crypto.randomUUID());
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            {!showForm ? (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setShowForm(!showForm);
                  }}
                >
                  {t("orders.addNewOrder")} +
                </Button>
              </Grid>
            ) : null}
            {showForm ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ border: "1px solid #cbcbcb", borderRadius: 5 }}
                >
                  <Title>{t("orders.billingInfo")}</Title>
                  <BrowseContactsDialog
                    setClientInfoObject={(data) => {
                      setClientInfoObject();
                      data = {
                        ...data,
                        ...data.companyAdditionalInfo,
                        ...data.contactAdditionalInfo,
                      };
                      // This rehreshes the form
                      setTimeout(() => {
                        setClientInfoObject(data);
                      }, 100);
                    }}
                    activeObject={
                      clientInfoObject &&
                      Object.values(clientInfoObject).find((v) => v !== "")
                        ? clientInfoObject
                        : undefined
                    }
                  />

                  {clientInfoObject &&
                  JSON.stringify(clientInfoObject) !== JSON.stringify({}) ? (
                    <>
                      <Tooltip
                        disableHoverListener={!clientInfoObject.companyId}
                        title={
                          <Typography style={{ fontSize: 15 }}>
                            {t("orders.editCompanyNameWarning")}
                          </Typography>
                        }
                      >
                        <TextField
                          style={{ marginTop: 5 }}
                          fullWidth
                          label={t("contactInfo.companyName")}
                          value={clientInfoObject.companyName}
                          onChange={(e) => {
                            editClientInfo(e.target.value, "companyName");
                          }}
                          disabled={!!clientInfoObject.companyId}
                        ></TextField>
                      </Tooltip>
                      <TextField
                        fullWidth
                        label={t("contactInfo.companyAddress")}
                        value={clientInfoObject.companyAddress}
                        multiline
                        rows={3}
                        onChange={(e) => {
                          editClientInfo(e.target.value, "companyAddress");
                        }}
                      ></TextField>

                      <TextField
                        fullWidth
                        label={"VAT ID"}
                        value={clientInfoObject.companyVatId}
                        onChange={(e) => {
                          editClientInfo(e.target.value, "companyVatId");
                        }}
                      ></TextField>
                      <TextField
                        label={t("contactInfo.contactName")}
                        fullWidth
                        value={clientInfoObject.contactName}
                        onChange={(e) => {
                          editClientInfo(e.target.value, "contactName");
                        }}
                      ></TextField>
                      <TextField
                        fullWidth
                        label={t("contactInfo.contactEmail")}
                        value={clientInfoObject.contactEmail}
                        onChange={(e) => {
                          editClientInfo(e.target.value, "contactEmail");
                        }}
                      ></TextField>
                      <TextField
                        fullWidth
                        label={t("contactInfo.contactPhone")}
                        value={clientInfoObject.contactPhone}
                        onChange={(e) => {
                          editClientInfo(e.target.value, "contactPhone");
                        }}
                      ></TextField>
                      <Button
                        fullWidth
                        variant="outlined"
                        style={{ marginTop: 5 }}
                        endIcon={
                          saveContact ? (
                            <Done style={{ color: "green" }} />
                          ) : null
                        }
                        onClick={() => {
                          setSaveContact(!saveContact);
                        }}
                      >
                        {t("contactInfo.saveContactInfo")}
                      </Button>
                      <Button
                        fullWidth
                        variant="outlined"
                        style={{ marginTop: 5 }}
                        endIcon={
                          differentShippingAddress ? (
                            <Done style={{ color: "green" }} />
                          ) : null
                        }
                        onClick={() => {
                          if (differentShippingAddress) {
                            setShippingInfo();
                          }
                          setDifferentShippingAddress(
                            !differentShippingAddress
                          );
                        }}
                      >
                        {t("orders.differentShippingAddress")}
                      </Button>
                    </>
                  ) : null}
                </Grid>
                {differentShippingAddress ? (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    style={{
                      border: "1px solid #cbcbcb",
                      borderRadius: 5,
                      marginLeft: width === "xs" ? "" : 5,
                    }}
                  >
                    <Title>{t("orders.shippingInfo")}</Title>
                    <BrowseContactsDialog
                      setClientInfoObject={(data) => {
                        setShippingInfo();
                        data = {
                          ...data,
                          ...data.companyAdditionalInfo,
                          ...data.contactAdditionalInfo,
                        };
                        // This rehreshes the form
                        setTimeout(() => {
                          setShippingInfo(data);
                        }, 100);
                      }}
                      activeObject={
                        shippingInfo &&
                        Object.values(shippingInfo).find((v) => v !== "")
                          ? shippingInfo
                          : undefined
                      }
                    />
                    <Button
                      style={{ marginTop: 5 }}
                      variant="outlined"
                      onClick={() => {
                        if (
                          !shippingInfo ||
                          !Object.keys(shippingInfo).length
                        ) {
                          setShippingInfo({
                            unknownReceiver: true,
                          });
                        } else {
                          var s = { ...shippingInfo };
                          delete s.unknownReceiver;
                          setShippingInfo(s);
                        }
                      }}
                      endIcon={
                        shippingInfo && shippingInfo.unknownReceiver ? (
                          <Done style={{ color: "green" }} />
                        ) : null
                      }
                    >
                      {t("orders.unknownReceiver")}
                    </Button>
                    {shippingInfo &&
                    JSON.stringify(shippingInfo) !== JSON.stringify({}) ? (
                      <>
                        <TextField
                          style={{ marginTop: 5 }}
                          fullWidth
                          label={t("contactInfo.companyName")}
                          value={shippingInfo.companyName}
                          onChange={(e) => {
                            editShippingInfo(e.target.value, "companyName");
                          }}
                        ></TextField>
                        <TextField
                          fullWidth
                          label={t("contactInfo.companyAddress")}
                          value={shippingInfo.companyAddress}
                          multiline
                          rows={3}
                          onChange={(e) => {
                            editShippingInfo(e.target.value, "companyAddress");
                          }}
                        ></TextField>

                        <TextField
                          fullWidth
                          label={"VAT ID"}
                          value={shippingInfo.companyVatId}
                          onChange={(e) => {
                            editShippingInfo(e.target.value, "companyVatId");
                          }}
                        ></TextField>
                        <TextField
                          label={t("contactInfo.contactName")}
                          fullWidth
                          value={shippingInfo.contactName}
                          onChange={(e) => {
                            editShippingInfo(e.target.value, "contactName");
                          }}
                        ></TextField>
                        <TextField
                          fullWidth
                          label={t("contactInfo.contactEmail")}
                          value={shippingInfo.contactEmail}
                          onChange={(e) => {
                            editShippingInfo(e.target.value, "contactEmail");
                          }}
                        ></TextField>
                        <TextField
                          fullWidth
                          label={t("contactInfo.contactPhone")}
                          value={shippingInfo.contactPhone}
                          onChange={(e) => {
                            editShippingInfo(e.target.value, "contactPhone");
                          }}
                        ></TextField>
                      </>
                    ) : null}
                  </Grid>
                ) : null}
                {clientInfoObject && Object.keys(clientInfoObject).length ? (
                  <Grid item xs={6} sm={2}>
                    <TextField
                      label={t("orders.invoiceIssuer")}
                      defaultValue={
                        clientInfoObject.location !== "Finland"
                          ? "estonia"
                          : "finland"
                      }
                      variant="outlined"
                      onChange={(e, v) => {
                        setIssuer(e.target.value);
                      }}
                      select
                    >
                      <MenuItem value={"estonia"}>CTN Estonia</MenuItem>
                      <MenuItem value={"finland"}>CTN Finland</MenuItem>
                    </TextField>
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    rows={4}
                    multiline
                    label={t("details")}
                    variant="outlined"
                    onChange={(e, v) => {
                      if (e.target && e.target.value) {
                        setDetails(e.target.value);
                        return;
                      }
                      setDetails();
                    }}
                  />
                </Grid>
                <Grid item container xs={12} spacing={width === "xs" ? "" : 3}>
                  <Grid item xs={12}>
                    <Title>{t("orders.itemSearch")}</Title>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      defaultChecked={false}
                      control={
                        <Checkbox
                          onChange={(e, v) => {
                            setProductFilter(!productFilter);
                          }}
                          defaultChecked={false}
                          color="primary"
                        />
                      }
                      label={t("orders.showOnlyDevices")}
                    />
                  </Grid>
                  <StockItemSearch
                    allowNewItems={false}
                    onChange={(e, v) => {
                      if (v && v.sku) {
                        setSelectedProducts([
                          ...selectedProducts,
                          { ...v, count: 1, price: v.listPrice },
                        ]);
                        setTotalItems(totalItems + 1);
                        setTotalPrice(totalPrice + (v.listPrice || 0));
                      }
                    }}
                    clearOnSelect={true}
                    includeQuantity={true}
                    includeTags={true}
                    includePacking={true}
                    placeholder={t("orders.startSearchingProducts")}
                    filters={
                      productFilter
                        ? {
                            Tags: [
                              "eCabin",
                              "CryoCabin",
                              "XCryo",
                              "XTone",
                              "OxyPro",
                              "LedPro",
                              "XGun",
                            ],
                          }
                        : undefined
                    }
                  />

                  {selectedProducts.length ? (
                    <>
                      {width === "xs" ? null : (
                        <Grid
                          container
                          style={{ borderTop: "1px solid #000", padding: 5 }}
                        >
                          {width === "xs" ? null : (
                            <Grid item sm={1} xs={1}></Grid>
                          )}
                          <Grid item sm={3} xs={3} style={{ padding: 5 }}>
                            SKU
                          </Grid>
                          {width === "xs" ? null : (
                            <Grid item sm={3} xs={3} style={{ padding: 5 }}>
                              {t("description")}
                            </Grid>
                          )}
                          <Grid item sm={1} xs={3} style={{ padding: 5 }}>
                            {t("orders.numberItems")}
                          </Grid>
                          <Grid item sm={1} xs={3} style={{ padding: 5 }}>
                            {t("orders.unitPrice")}
                          </Grid>
                          <Grid item sm={1} xs={3} style={{ padding: 5 }}>
                            {t("orders.discount")}%
                          </Grid>
                          <Grid item sm={2} xs={3} style={{ padding: 5 }}>
                            {t("orders.unitTotal")}
                          </Grid>
                          {width === "xs" ? null : (
                            <Grid item sm={1} xs={1}></Grid>
                          )}
                        </Grid>
                      )}
                      {selectedProducts.map((dev, i) => (
                        <OrderLine
                          key={i}
                          item={dev}
                          selectedProducts={selectedProducts}
                          setSelectedProducts={setSelectedProducts}
                          totalItems={totalItems}
                          setTotalItems={setTotalItems}
                          totalPrice={totalPrice}
                          setTotalPrice={setTotalPrice}
                          width={width}
                        />
                      ))}
                      <Grid
                        container
                        style={{
                          padding: 5,
                          borderTop: "1px solid #000",
                          marginTop: 5,
                        }}
                      >
                        {width === "xs" ? null : (
                          <>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={3}></Grid>{" "}
                          </>
                        )}
                        <Grid item sm={1} xs={3} style={{ padding: 5 }}>
                          {t("orders.totalItems")}
                        </Grid>
                        <Grid
                          item
                          sm={1}
                          xs={2}
                          style={{ padding: 5, textAlign: "right" }}
                        >
                          {totalItems}
                        </Grid>
                        <Grid item sm={1} xs={3} style={{ padding: 5 }}>
                          {t("orders.totalPrice")}
                        </Grid>
                        <Grid
                          item
                          sm={2}
                          xs={2}
                          style={{ padding: 5, textAlign: "right" }}
                        >
                          {(totalPrice ? totalPrice : 0).toFixed(2)}
                        </Grid>
                        <Grid item sm={1} xs={2}>
                          <TextField
                            select
                            value={currency}
                            onChange={(e, v) => {
                              setCurrency(e.target.value);
                            }}
                          >
                            <MenuItem key={"USD"} value={"USD"}>
                              USD
                            </MenuItem>
                            <MenuItem key={"EUR"} value={"EUR"}>
                              EUR
                            </MenuItem>
                          </TextField>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        style={{
                          padding: 10,
                          borderTop: "1px solid #000",
                          marginTop: 5,
                        }}
                        alignContent="center"
                      >
                        {width === "xs" ? null : <Grid item xs={6}></Grid>}
                        <Grid item xs={4} sm={2}>
                          <Typography>
                            {t("orders.totalPrice") + " + " + t("orders.vat")}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                          <TextField
                            label={t("orders.vat") + " %"}
                            type="number"
                            size="small"
                            variant="outlined"
                            defaultValue={vat || ""}
                            onChange={(e) => {
                              setVat(Number(e.target.value));
                            }}
                          ></TextField>
                        </Grid>
                        <Grid item sm={1} xs={2} style={{ textAlign: "right" }}>
                          {(totalPrice * (1 + vat / 100)).toFixed(2)}
                        </Grid>
                        <Grid item sm={1} xs={2}>
                          {currency}
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid item xs={12} container>
                  <Grid item xs={12}>
                    <Typography>{t("orders.invoiceLanguage")}</Typography>
                  </Grid>

                  <TextField
                    select
                    defaultValue={i18n.language}
                    onChange={(e, v) => {
                      setInvoiceLang(e.target.value);
                    }}
                    variant="outlined"
                  >
                    <MenuItem value="en" key={"en"} label="English">
                      English
                    </MenuItem>
                    <MenuItem value="fi" key={"fi"} label="Suomi">
                      Suomi
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item sm={6} xs={12} container spacing={1}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => {
                        setShowPreview(true);
                      }}
                    >
                      {t("preview")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={submitOrder}
                    >
                      {t("submit")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={resetForm}
                    >
                      {t("cancel")}
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : null}
          </Grid>
        </Paper>
      </Grid>
      {/* Preview needs to be outside the Grid otherwise the PDF print does not zoom correctly */}
      <InvoicePreviewWindow
        show={showPreview}
        submit={submit}
        setSubmit={setSubmit}
        setShow={setShowPreview}
        selectedProducts={selectedProducts}
        totalItems={totalItems}
        totalPrice={totalPrice}
        currency={currency}
        clientInfoObject={clientInfoObject}
        shippingInfo={shippingInfo}
        issuer={issuer}
        saveContact={saveContact}
        invoiceLang={invoiceLang}
        width={width}
        vat={vat}
        details={details}
        orderNumber={orderNumber}
      />
    </>
  );
}

export default withWidth()(NewOrder);
