import { MenuItem, withWidth } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Title from "../../../Components/Title";

import { useDispatch } from "react-redux";
import OrderSearchAutocomplete from "../../../Components/OrderSearchAutocomplete";
import {
  getToken,
  requestErrorHandler,
  shipmentStatus,
  shippingMethods,
  warehouseLocation,
} from "../../../Utils/Common";
import EnvSettings from "../../../Utils/EnvSettings";
import ShippingAddress from "../ShippingAddress";
import ShippingItemTable from "../ShippingItemTable";
// import ShippingItemLine from "./ShippingItemLine";
/**
 * Delivery to
 * Items
 * External Ref
 * Order
 * Term
 * Shipping Company
 * Status
 * Pickup
 * ETA
 * Tracking number
 */

const server = EnvSettings.server;

function OrderShipment({ width, shipmentData, setShipmentData, orderId }) {
  const [order, setOrder] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (order && !shipmentData) {
      var obj = {
        ...shipmentData,
        Order: order,
        shippingInfo: order.shippingInfo || order.clientInfo,
        pickupAddress: "mailhouse",
        genericDevices: order.genericDevices,
        status: "waiting",
      };
      setShipmentData(JSON.parse(JSON.stringify(obj)));
    }
  }, [order]);

  const getOrder = (ser_val) => {
    if (!ser_val) {
      return;
    }
    // setLoadingUsersList(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        search_value: ser_val,
      }),
    };
    fetch(server + "/get_orders", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          if (result.orders && result.orders.length === 1) {
            setOrder(result.orders[0]);
          }
          // setOrder(result.orders);
        }
        // setLoadingUsersList(false);
      });
  };

  useEffect(() => {
    if (orderId) {
      getOrder(orderId);
    }
    return () => {
      // Cleanup
      setOrder();
    };
  }, []);

  return (
    <>
      <Grid item xs={12}>
        {!order ? (
          <OrderSearchAutocomplete
            additionalDataFields={[
              "clientInfo.companyName",
              "clientInfo.location",
              "totalPrice",
              "currency",
            ]}
            additionalFilters={["transit"]}
            onChange={(e, v) => {
              if (v) {
                setOrder(v);
              }
            }}
          />
        ) : (
          <Title>
            {t("shipments.shipmentForOrder")} {order.number}
          </Title>
        )}
      </Grid>
      {shipmentData ? (
        <>
          <Grid item xs={6} sm={4}>
            <ShippingAddress
              editable={true}
              setShipmentData={setShipmentData}
              shipmentData={shipmentData}
            />
          </Grid>
          <Grid
            container
            direction="column"
            item
            sm={4}
            justify="space-between"
          >
            {["shippingCompany", "externalRef", "shippingTerm"].map((m, i) => (
              <TextField
                key={m + i}
                label={t("shipments." + m)}
                variant="outlined"
                fullWidth
                onChange={(e, v) => {
                  if (!e || !e.target) {
                    return;
                  }
                  var obj = { ...shipmentData, [m]: e.target.value };
                  setShipmentData({ ...obj });
                }}
              />
            ))}
            <TextField
              label={t("shipments.pickupAddress")}
              defaultValue={"mailhouse"}
              variant="outlined"
              onChange={(e, v) => {
                if (!e || !e.target.value) {
                  return;
                }
                var obj = { ...shipmentData, pickupAddress: e.target.value };
                setShipmentData({ ...obj });
              }}
              fullWidth
              select
            >
              {warehouseLocation.map((m, i) => (
                <MenuItem key={m.value + i} value={m.value}>
                  {m.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label={t("shipments.shippingMethod")}
              variant="outlined"
              fullWidth
              select
              onChange={(e, v) => {
                if (!e || !e.target.value) {
                  return;
                }
                var obj = { ...shipmentData, shippingMethod: e.target.value };
                setShipmentData({ ...obj });
              }}
            >
              {shippingMethods.map((s, i) => (
                <MenuItem value={s.value} key={i}>
                  {t("shippingMethods." + s.value)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            container
            direction="column"
            item
            sm={4}
            justify="space-between"
            style={{ minHeight: 325 }}
          >
            {["pickupDate", "eta"].map((m, i) => (
              <TextField
                key={m + i}
                label={t("shipments." + m)}
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type={"date"}
                onChange={(e, v) => {
                  if (!e || !e.target) {
                    return;
                  }
                  var obj = { ...shipmentData, [m]: e.target.value };
                  setShipmentData({ ...obj });
                }}
              />
            ))}
            <TextField
              label={t("shipments.trackingNumber")}
              variant="outlined"
              fullWidth
              onChange={(e, v) => {
                if (!e || !e.target) {
                  return;
                }
                var obj = { ...shipmentData, trackingNumber: e.target.value };
                setShipmentData({ ...obj });
              }}
            />
            <TextField
              label={t("shipments.status")}
              variant="outlined"
              fullWidth
              defaultValue={"waiting"}
              select
              onChange={(e, v) => {
                if (!e || !e.target) {
                  return;
                }
                var obj = { ...shipmentData, status: e.target.value };
                setShipmentData({ ...obj });
              }}
            >
              {shipmentStatus.map((p, i) => (
                <MenuItem value={p.value} key={i}>
                  {t("shipmentStates." + p.value)}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label={t("shipments.totalPrice")}
              type="number"
              value={shipmentData.totalPrice}
              variant="outlined"
              fullWidth
              onChange={(e, v) => {
                if (!e || !e.target) {
                  return;
                }
                var obj = { ...shipmentData, totalPrice: e.target.value };
                setShipmentData({ ...obj });
              }}
            />
          </Grid>
          <Grid item container xs={12} spacing={width === "xs" ? "" : 3}>
            {order.genericDevices.length ? (
              <ShippingItemTable
                listOfItems={order.genericDevices}
                shipmentData={shipmentData}
                setShipmentData={setShipmentData}
                width={width}
              />
            ) : null}
            <Grid item xs={12}>
              <TextField
                fullWidth
                rows={2}
                multiline
                label={t("details")}
                variant="outlined"
                onChange={(e, v) => {
                  if (!e || !e.target) {
                    return;
                  }
                  var obj = { ...shipmentData, details: e.target.value };
                  setShipmentData({ ...obj });
                }}
              />
            </Grid>
          </Grid>
        </>
      ) : null}
    </>
  );
}

export default withWidth()(OrderShipment);
