import {
  Breadcrumbs,
  CircularProgress,
  Link,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import BadgeLink from "../../../Components/BadgeLink";
import SortableTable from "../../../Components/SortableTable";
import Title from "../../../Components/Title";
import {
  deviceProfiles,
  ECABIN,
  formatTimeWithTZOffset,
  getLastParameter,
  getToken,
  requestErrorHandler,
} from "../../../Utils/Common";
import EnvSettings from "../../../Utils/EnvSettings";
import { useStyles } from "../../../Utils/Styles";

const server = EnvSettings.server;

export default function ReleaseList() {
  const classes = useStyles();
  const [softwarePackages, setSoftwarePackages] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const type = getLastParameter();
  const formatSWData = (data) => {
    var arr = [];
    if (data && Array.isArray(data)) {
      data.forEach((d) => {
        var obj = {
          version: d.filename.replace(".zip", "").split("").join("."),
          date: formatTimeWithTZOffset(d.date, ECABIN).substring(0, 10),
          count: 0,
          percentage: 0,
        };
        arr.push(obj);
      });
    }
    return arr;
  };

  const getSoftwarePackages = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        type: type,
      }),
    };
    fetch(server + "/get_software_packages", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          getDevices(formatSWData(result.packages));
        }
      });
  };

  const getDevices = (packages) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        requireLCS: false,
        search_value:
          deviceProfiles.find(
            (f) =>
              f.name.replace("°", "").replace(" ", "").toLowerCase() === type
          )?.prefix || "EC2",
      }),
    };
    fetch(server + "/get_admin_devices", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          if (result.devices) {
            var arr = packages;
            result.devices.forEach((d) => {
              var i = packages.findIndex((s) => s.version === d.version);
              if (i === -1) {
                return;
              }
              var s = arr[i];
              s.count = s.count + 1;
              s.percentage = s.count / result.devices.length;
              arr[i] = s;
            });
            setSoftwarePackages(arr);
          }
        }
      });
  };

  useEffect(getSoftwarePackages, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href={"/cpanel/software"}>
            {t("software.header")}
          </Link>
          <Link color="inherit" href={"/cpanel/software/releases"}>
            {t("software.releases")}
          </Link>
          <Typography color="textPrimary">{type.toUpperCase()}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Title>
            {t("software.releases")}: {type.toUpperCase()}
          </Title>
          <Grid item xs={2}>
            <BadgeLink
              href={"/cpanel/software/prereleases/" + type}
              title={"Prereleases"}
            />
          </Grid>
          <Grid item xs={10}></Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Title>{t("softwareReleases")}</Title>
          {softwarePackages ? (
            <SortableTable
              headCells={[
                {
                  id: "version",
                  numeric: false,
                  disablePadding: true,
                  label: t("version"),
                },
                {
                  id: "date",
                  numeric: false,
                  disablePadding: true,
                  label: t("releaseDate"),
                },
                {
                  id: "count",
                  numeric: false,
                  disablePadding: true,
                  label: "Active installs",
                },
                {
                  id: "percentage",
                  numeric: false,
                  disablePadding: true,
                  label: "%",
                },
              ]}
              defaultSortRow={"date"}
              handleRowClick={() => {}}
              tableData={softwarePackages}
              rowsPerPage={200}
              disablePagination={false}
              tableSize={"small"}
              specialDataFormatting={[
                {
                  id: "percentage",
                  format: (d) => (d * 100).toFixed(2),
                },
              ]}
            />
          ) : (
            <CircularProgress />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
