import { IconButton, MenuItem } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Clear from "@material-ui/icons/Clear";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DeviceProfileSearchAutocomplete from "../../../../Components/DeviceProfileSearchAutocomplete";
import Title from "../../../../Components/Title";

export default function DeviceSection({ unedited, emaillist, setEmailList }) {
  unedited = unedited || emaillist;
  const { t } = useTranslation();
  const [resetKey, setResetKey] = useState("sdfasfsda");

  return (
    <Grid item sm={3} xs={12}>
      <Title>{t("emailLists.deviceProfiles")}</Title>
      {unedited &&
        unedited.EmailListDeviceProfiles &&
        unedited.EmailListDeviceProfiles.map((u, i) =>
          u && u.DeviceProfile ? (
            <MenuItem
              key={i}
              style={
                emaillist.EmailListDeviceProfiles.find(
                  (uu) => uu.DeviceProfile.name === u.DeviceProfile.name
                )
                  ? {}
                  : { textDecoration: "line-through" }
              }
            >
              {u.DeviceProfile.name}
              <IconButton
                style={{ position: "absolute", right: 0 }}
                onClick={() => {
                  setEmailList({
                    ...emaillist,
                    EmailListDeviceProfiles:
                      emaillist.EmailListDeviceProfiles.filter(
                        (f) => f.DeviceProfile?.id !== u.DeviceProfile?.id
                      ),
                  });
                }}
              >
                <Clear />
              </IconButton>
            </MenuItem>
          ) : null
        )}
      {emaillist &&
        unedited &&
        emaillist.EmailListDeviceProfiles &&
        emaillist.EmailListDeviceProfiles.filter(
          (u) =>
            !unedited.EmailListDeviceProfiles.find(
              (f) => u.DeviceProfile?.id === f.DeviceProfile?.id
            )
        ).map((u, i) => (
          <MenuItem
            key={i}
            style={{
              backgroundColor: "rgb(177 255 184)",
            }}
          >
            {u.DeviceProfile.name}
            <IconButton
              style={{ position: "absolute", right: 0 }}
              onClick={() => {
                setEmailList({
                  ...emaillist,
                  EmailListDeviceProfiles:
                    emaillist.EmailListDeviceProfiles.filter(
                      (f) => f.DeviceProfile.id !== u.DeviceProfile.id
                    ),
                });
              }}
            >
              <Clear />
            </IconButton>
          </MenuItem>
        ))}
      <DeviceProfileSearchAutocomplete
        style={{ marginTop: 5 }}
        key={resetKey}
        label={t("emailLists.searchDeviceProfile")}
        onChange={(e, v) => {
          if (!v || !v.name) {
            return;
          }
          setEmailList({
            ...emaillist,
            EmailListDeviceProfiles: [
              ...(emaillist.EmailListDeviceProfiles || []),
              { DeviceProfile: v },
            ],
          });
          setResetKey(new Date().toISOString());
        }}
      />
    </Grid>
  );
}
