import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";
import { useTranslation } from "react-i18next";

export default function ConfirmDeleteDialog({
  showDialog,
  deleteOrder,
  onClose,
}) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={showDialog}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t("shipments.deleteShipment")}
      </DialogTitle>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined" autoFocus>
          {t("no")}
        </Button>
        <Button onClick={deleteOrder} color="secondary" variant="contained">
          {t("yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
