import {
  Dialog,
  DialogContent,
  IconButton,
  ListItem,
  TextField,
  Tooltip,
  Typography,
  withWidth,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ChatIcon from "@material-ui/icons/Chat";
import InfoIcon from "@material-ui/icons/Info";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ControlledSelect from "../../../Components/ControlledSelect";
import DialogAppBar from "../../../Components/DialogAppBar";
import MediaFiles from "../../../Components/MediaFiles";
import NoteSection from "../../../Components/NoteSection";
import Title from "../../../Components/Title";
import {
  ECABIN,
  formatTimeWithTZOffset,
  getToken,
  paymentStates,
  requestErrorHandler,
  shipmentStatus,
  USER_GROUPS,
} from "../../../Utils/Common";
import EnvSettings from "../../../Utils/EnvSettings";
import DetailTabs from "../../DeviceDetails/DetailTabs";
import OrderLine from "../OrderLine";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";

const server = EnvSettings.server;

function EditOrder({ selectedOrder, width }) {
  const history = useHistory();
  const [editable, setEditable] = useState(false);
  const [editedOrder, setEditedOrder] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const deleteOrder = () => {
    if (!editedOrder) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        orderData: editedOrder,
      }),
    };
    fetch(server + "/delete_resupply_order", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          window.location.reload();
        }
      });
  };

  const editOrder = () => {
    if (!editedOrder) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        orderData: editedOrder,
      }),
    };
    fetch(server + "/edit_resupply_order", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          window.location.reload();
        }
      });
  };

  useEffect(() => {
    if (selectedOrder) {
      setEditedOrder(JSON.parse(JSON.stringify(selectedOrder)));
    }
    // eslint-disable-next-line
  }, [selectedOrder, setEditedOrder]);

  const handleExit = () => {
    setEditable(false);
    setEditedOrder();
  };

  return editedOrder ? (
    <Dialog open={!!editedOrder} fullScreen onClose={handleExit}>
      <DialogContent>
        <DialogAppBar header={t("orders.orderDetails")} onClose={handleExit} />
        <Grid
          container
          spacing={1}
          style={{
            marginTop: 60,
            marginBottom: 20,
            paddingBottom: 30,
            position: "relative",
          }}
        >
          <ConfirmDeleteDialog
            onClose={() => {
              setShowDialog(false);
            }}
            showDialog={showDialog}
            deleteOrder={deleteOrder}
          />
          <Grid item container sm={6} xs={12}>
            <Grid item xs={12}>
              <Typography>
                {t("orders.orderNumber")}: {editedOrder.number}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {t("orders.invoiceIssuer")}: {editedOrder.invoiceIssuer}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {t("createdBy")}: {editedOrder.User.fullName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {t("createdAt")}:{" "}
                {formatTimeWithTZOffset(editedOrder.createdAt, ECABIN)}
              </Typography>
            </Grid>
            {editedOrder.paymentDate ? (
              <Grid item xs={12}>
                <Typography>
                  {t("orders.paymentDate")}:{" "}
                  {formatTimeWithTZOffset(editedOrder.paymentDate, ECABIN)}
                </Typography>
              </Grid>
            ) : null}
            {editedOrder.deliveryDate ? (
              <Grid item xs={12}>
                <Typography>
                  {t("orders.deliveryDate")}:{" "}
                  {formatTimeWithTZOffset(editedOrder.deliveryDate, ECABIN)}
                </Typography>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <ControlledSelect
                itemlist={paymentStates}
                label={t("orders.paymentStatus")}
                defaultValue={editedOrder.paymentStatus}
                onChange={(e, v) => {
                  if (!e.target?.value) {
                    setEditedOrder({
                      ...editedOrder,
                      paymentStatus: selectedOrder.paymentStatus,
                    });
                    return;
                  }
                  setEditedOrder({
                    ...editedOrder,
                    paymentStatus: e.target.value,
                  });
                }}
                deleteButton={true}
                disabled={!editable}
                usingTranslation={(data) => {
                  return t("paymentStates." + data);
                }}
              />
            </Grid>
            {editedOrder.paymentStatus === "deposit" ? (
              <Grid item xs={12} style={{ marginTop: 5 }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label={t("orders.depositAmount")}
                  type="number"
                  defaultValue={editedOrder.depositAmount || ""}
                  disabled={!editable}
                  inputProps={{ min: 0, max: editedOrder.totalPrice }}
                  onChange={(e, v) => {
                    if (!e.target?.value) {
                      setEditedOrder({ ...editedOrder, depositAmount: null });
                      return;
                    }
                    setEditedOrder({
                      ...editedOrder,
                      depositAmount: e.target.value,
                    });
                  }}
                ></TextField>
              </Grid>
            ) : null}
            {editedOrder.Shipments ? (
              <>
                <Title>
                  {t("shipments.header")}
                  {editedOrder.multipleShipments ? (
                    <Tooltip
                      title={
                        <Typography style={{ fontSize: 15 }}>
                          {t("orders.multipleShipments")}
                        </Typography>
                      }
                    >
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </Title>

                {editedOrder.Shipments.map((m) => (
                  <Tooltip title={"Show Shipment"}>
                    <ListItem
                      button
                      onClick={() => {
                        history.push("/cpanel/shipments/" + m.number);
                      }}
                      style={{
                        border: "1px solid #000",
                        backgroundColor:
                          shipmentStatus.find((f) => f.value === m.status)
                            ?.color || "",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography style={{ fontSize: 15 }}>
                            {m.number} -{" "}
                            {t(
                              "shipmentStates." +
                                (shipmentStatus.find(
                                  (f) => f.value === m.status
                                )?.value || "waiting")
                            )}
                          </Typography>
                        </Grid>
                        {m.genericDevices &&
                          m.genericDevices.map((mm) =>
                            mm.count ? (
                              <Grid item xs={12}>
                                <Typography style={{ fontSize: 12 }}>
                                  {mm.name + " x " + mm.count}
                                </Typography>
                              </Grid>
                            ) : null
                          )}
                      </Grid>
                    </ListItem>
                  </Tooltip>
                ))}
                {editedOrder.paymentStatus === "completed" ||
                editedOrder.paymentStatus === "transit" ||
                editedOrder.paymentStatus === "deposit" ? (
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        history.push(
                          "/cpanel/shipments/new/resupply/" + editedOrder.number
                        );
                      }}
                      style={{ marginTop: 5 }}
                    >
                      {t("orders.addNewShipment")}
                    </Button>
                  </Grid>
                ) : null}
              </>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontWeight: "bold" }}>
              {t("details")}
            </Typography>
            <Typography>{editedOrder.details}</Typography>
          </Grid>
          <Grid item xs={12}>
            <DetailTabs
              activeTabs={[
                {
                  permission: [USER_GROUPS.ADMIN, USER_GROUPS.SUPER_USER],
                  label: (
                    <>
                      <ShoppingCartIcon /> {t("items")}
                    </>
                  ),
                  content: (
                    <>
                      <Grid
                        item
                        xs={12}
                        container
                        style={{ borderTop: "1px solid #000", marginTop: 10 }}
                      >
                        <Grid item sm={3} xs={3}>
                          <Typography>{t("description")}</Typography>
                        </Grid>
                        <Grid item sm={2} xs={3}>
                          <Typography style={{ textAlign: "right" }}>
                            {t("orders.numberItems")}
                          </Typography>
                        </Grid>
                        {width === "xs" ? null : <Grid item xs={1}></Grid>}
                      </Grid>
                      {editedOrder.genericDevices &&
                        editedOrder.genericDevices.map((dev, i) => (
                          <OrderLine
                            key={i}
                            item={dev}
                            selectedProducts={editedOrder.genericDevices}
                            // totalItems={totalItems}
                            // totalPrice={totalPrice}
                            onlyPrint={true}
                            editedOrder={editedOrder}
                            setEditedOrder={setEditedOrder}
                            editable={editable}
                            width={width}
                          />
                        ))}
                      <Grid
                        item
                        container
                        xs={12}
                        style={{ borderTop: "1px solid #000" }}
                      >
                        {width === "xs" ? null : <Grid item xs={2}></Grid>}
                        <Grid item sm={2} xs={3}>
                          <Typography
                            style={{ textAlign: "right", padding: 5 }}
                          >
                            {t("orders.totalItems")}
                          </Typography>
                        </Grid>
                        <Grid item sm={1} xs={3}>
                          <Typography
                            style={{ textAlign: "right", padding: 5 }}
                          >
                            {editedOrder.genericDevices.reduce(
                              (partialSum, a) => partialSum + a.count,
                              0
                            )}
                          </Typography>
                        </Grid>
                        {width === "xs" ? null : <Grid item xs={4}></Grid>}
                        <Grid item sm={1} xs={3}>
                          <Typography
                            style={{ textAlign: "right", padding: 5 }}
                          >
                            {t("orders.totalPrice")}
                          </Typography>
                        </Grid>
                        <Grid item sm={1} xs={3}>
                          <Typography
                            style={{ textAlign: "right", padding: 5 }}
                          >
                            {editedOrder.totalPrice.toFixed(2)}{" "}
                            {editedOrder.currency}
                          </Typography>
                        </Grid>
                        {width === "xs" ? null : <Grid item xs={1}></Grid>}
                      </Grid>
                    </>
                  ),
                },
                {
                  permission: [USER_GROUPS.ADMIN, USER_GROUPS.SUPER_USER],
                  label: (
                    <>
                      <AttachFileIcon
                        style={{ fontSize: 20, verticalAlign: "middle" }}
                      />
                      {t("media")}
                    </>
                  ),
                  content: (
                    <MediaFiles
                      resource={{
                        type: "resupply",
                        number: editedOrder.number,
                      }}
                      listCallback={() => {}}
                    />
                  ),
                },
                {
                  permission: [USER_GROUPS.ADMIN, USER_GROUPS.SUPER_USER],
                  label: (
                    <>
                      <ChatIcon />
                      {t("notes")}
                    </>
                  ),

                  content: (
                    <NoteSection
                      parent={{ ResupplyOrderId: editedOrder.id }}
                      notes={editedOrder.Notes}
                      reload={() => {
                        window.location.reload();
                      }}
                      noPaper={true}
                    />
                  ),
                },
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              onClick={() => {
                if (editable) {
                  setEditedOrder(JSON.parse(JSON.stringify(selectedOrder)));
                }
                setEditable(!editable);
              }}
            >
              {!editable ? t("edit") : t("cancel")}
            </Button>
            {editable ? (
              <>
                <Button
                  variant="contained"
                  disabled={
                    JSON.stringify(editedOrder) ===
                    JSON.stringify(selectedOrder)
                  }
                  color="primary"
                  onClick={editOrder}
                >
                  {t("save")}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setShowDialog(true);
                  }}
                >
                  {t("delete")}
                </Button>
              </>
            ) : null}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  ) : null;
}

export default withWidth()(EditOrder);
