import {
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Title from "../../../Components/Title";
import { getToken, requestErrorHandler } from "../../../Utils/Common";
import EnvSettings from "../../../Utils/EnvSettings";
import { useStyles } from "../../../Utils/Styles";
import DeviceProfileSection from "./SubscriptionSections/DeviceProfileSection";
import DeviceSection from "./SubscriptionSections/DeviceSection";
import OrderSection from "./SubscriptionSections/OrderSection";
import ProductLineSection from "./SubscriptionSections/ProductLineSection";
import ResupplyOrderSection from "./SubscriptionSections/ResupplyOrderSection";
import ShipmentsSection from "./SubscriptionSections/ShipmentsSections";
import StockItemSection from "./SubscriptionSections/StockItemSection";
import UserSection from "./SubscriptionSections/UserSection";

const server = EnvSettings.server;

export default function NewEmailList(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [emaillist, setEmailList] = useState();
  const dispatch = useDispatch();
  const [resetKey, setResetKey] = useState("dsfafasd");

  const submitList = () => {
    if (!emaillist) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        list: emaillist,
        add_list: true,
      }),
    };
    fetch(server + "/edit_email_list", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          window.location.replace("/cpanel/emaillists");
        }
      });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Breadcrumbs>
                <Link color="inherit" href="/cpanel/emaillists">
                  <Typography>{t("emailLists.header")}</Typography>
                </Link>
                <Typography>{t("emailLists.editEmailList")}</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
              <Title>{t("emailLists.editEmailList")}</Title>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label={t("emailLists.name")}
                value={emaillist?.name}
                onChange={(e) => {
                  setEmailList({ ...emaillist, name: e.target.value });
                }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      onChange={(e, v) => {
                        setEmailList({
                          ...emaillist,
                          allOrderAlerts: v ? 1 : 0,
                        });
                      }}
                    />
                  }
                  label={t("emailLists.subscribeAllOrderAlerts")}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      onChange={(e, v) => {
                        setEmailList({
                          ...emaillist,
                          allStockAlerts: v ? 1 : 0,
                        });
                      }}
                    />
                  }
                  label={t("emailLists.subscribeAllStockAlerts")}
                />
              </Grid>
            </Grid>
            <UserSection emaillist={emaillist} setEmailList={setEmailList} />
            <DeviceSection emaillist={emaillist} setEmailList={setEmailList} />
            <ProductLineSection
              emaillist={emaillist}
              setEmailList={setEmailList}
            />
            <DeviceProfileSection
              emaillist={emaillist}
              setEmailList={setEmailList}
            />
            <OrderSection emaillist={emaillist} setEmailList={setEmailList} />
            <ResupplyOrderSection
              emaillist={emaillist}
              setEmailList={setEmailList}
            />
            <ShipmentsSection
              emaillist={emaillist}
              setEmailList={setEmailList}
            />
            <StockItemSection
              emaillist={emaillist}
              setEmailList={setEmailList}
            />

            <Grid item xs={12}>
              <Button
                disabled={!emaillist}
                variant="contained"
                color="primary"
                onClick={() => {
                  submitList();
                }}
              >
                {t("submit")}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
