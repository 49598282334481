import { List, ListItem, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import AddComment from "@material-ui/icons/AddComment";
import ClearIcon from "@material-ui/icons/Clear";
import SendIcon from "@material-ui/icons/Send";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  checkRequiredPermissions,
  ECABIN,
  findValueInText,
  formatTimeWithTZOffset,
  getToken,
  requestErrorHandler,
  USER_GROUPS,
} from "../Utils/Common";
import EnvSettings from "../Utils/EnvSettings";
import { useStyles } from "../Utils/Styles";
import Title from "./Title";
const server = EnvSettings.server;

export default function NoteSection({ notes, parent, reload, noPaper }) {
  const { t } = useTranslation();
  const [noteText, setNoteText] = useState();
  const [showAddNote, setShowAddNote] = useState();
  const [usersList, setUsersList] = useState();
  const [searchList, setSearchList] = useState();
  const [searchVal, setSearchVal] = useState();
  const [showUsers, setShowUsers] = useState(false);
  const [tagged, setTagged] = useState();
  const dispatch = useDispatch();
  const classes = useStyles();
  const ref = useRef();
  const addNote = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        note: noteText,
        ...(tagged ? { tagged: tagged } : {}),
        ...parent,
      }),
    };
    fetch(server + "/add_note", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setNoteText("");
          if (reload) {
            reload();
          }
        }
      });
  };

  const filterResults = () => {
    if (!searchVal && usersList) {
      setSearchList();
      return;
    }
    if (!searchVal && !usersList) {
      return;
    }
    var v = searchVal.replace("@", "");
    var arr =
      usersList &&
      usersList.filter((contact) => {
        return (
          findValueInText(contact.emailAddress, v) ||
          findValueInText(contact.userName, v) ||
          findValueInText(contact.fullName, v)
        );
      });
    if (arr) {
      setSearchList(arr);
      setTimeout(() => {
        setShowUsers(true);
      }, 1);
    }
  };

  useEffect(() => filterResults, [usersList, searchVal, dispatch]);

  useEffect(() => {
    const getUsers = () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: getToken(),
          userGroup: "CTN",
        }),
      };
      fetch(server + "/get_user_list", requestOptions)
        .then((res) => res.json())
        .then((result) => {
          requestErrorHandler(result, dispatch);
          if (!result.error) {
            setUsersList(result.users);
          }
        });
    };
    if (checkRequiredPermissions([USER_GROUPS.ADMIN])) {
      getUsers();
    }
  }, [setUsersList, dispatch]);

  const elements = () => {
    return (
      <>
        {" "}
        <Title>{t("notes")}</Title>
        <Grid container direction={"column"}>
          {notes &&
            notes.map((row, i) => (
              <Grid
                key={i}
                container
                direction={"row"}
                style={{
                  padding: 5,
                  marginBottom: 10,
                }}
              >
                <Grid item xs={2}>
                  <Typography>{row?.User?.fullName || "System"}</Typography>
                  <Typography>
                    {formatTimeWithTZOffset(row.createdAt, ECABIN)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs
                  style={{ borderLeft: "1px solid #d4d4d4", paddingLeft: 5 }}
                >
                  <Typography>{row.text}</Typography>
                </Grid>
              </Grid>
            ))}
        </Grid>
        <Button
          onClick={() => {
            setShowAddNote(!showAddNote);
          }}
        >
          <AddComment></AddComment>
          <Typography>{t("sessionDetails.addNote")}</Typography>
        </Button>
        {showAddNote ? (
          <Grid container style={{ flexDirection: "row" }}>
            <Grid item xs={11}>
              {showUsers ? (
                <Paper
                  style={{
                    position: "absolute",
                    zIndex: 2000,
                    height: 200,
                    minWidth: 200,
                    marginTop: -200,
                    overflowY: "scroll",
                  }}
                >
                  <List>
                    {searchList ? (
                      searchList.map((m, i) => (
                        <ListItem
                          key={i}
                          button
                          onClick={() => {
                            var arr = noteText.split(" ");
                            var v = arr.findIndex((f) => f && f.includes("@"));
                            if (v === -1) {
                              return;
                            }
                            setTagged([...(tagged ? tagged : []), m.id]);
                            arr[v] = m.fullName;
                            setNoteText(arr.join(" "));
                            setShowUsers(false);
                          }}
                        >
                          {m.emailAddress}
                        </ListItem>
                      ))
                    ) : (
                      <ListItem>{"No items found..."}</ListItem>
                    )}
                  </List>
                </Paper>
              ) : null}
              <TextField
                name="note"
                ref={ref}
                variant="outlined"
                required
                value={noteText}
                fullWidth
                id="note"
                label={t("sessionDetails.note")}
                onChange={(e) => {
                  if (e.target && e.target.value.includes("@")) {
                    var v = e.target.value
                      .split(" ")
                      .find((f) => f && f.includes("@"));
                    if (v.length > 1) {
                      setSearchVal(v);
                    }
                  } else {
                    setShowUsers(false);
                  }
                  if (e.target.value === "") {
                    setTagged();
                  }
                  setNoteText(e.target.value);
                }}
              />
              {tagged ? (
                <>
                  <Typography>Tagged:</Typography>
                  {tagged.map((t, k) => (
                    <Button
                      key={k}
                      onClick={() => {
                        setNoteText(
                          noteText.replace(
                            usersList.find((u) => t === u.id).fullName,
                            ""
                          )
                        );
                        var a = tagged.filter((f) => f !== t);
                        setTagged(a.length ? a : undefined);
                      }}
                      startIcon={<ClearIcon />}
                    >
                      {usersList.find((u) => t === u.id).fullName}
                    </Button>
                  ))}
                </>
              ) : null}
            </Grid>
            <Grid
              item
              xs={1}
              style={{ alignItems: "center", alignContent: "center" }}
            >
              <Button
                onClick={() => {
                  addNote();
                }}
              >
                <SendIcon></SendIcon>
              </Button>
            </Grid>
          </Grid>
        ) : undefined}
      </>
    );
  };

  if (noPaper) {
    return (
      <Grid item xs={12}>
        {elements()}
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>{elements()}</Paper>
    </Grid>
  );
}
