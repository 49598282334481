import { IconButton, MenuItem } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Clear from "@material-ui/icons/Clear";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ProductLineSearchAutocomplete from "../../../../Components/ProductLineSearchAutocomplete";
import Title from "../../../../Components/Title";
export default function ProductLineSection({
  unedited,
  emaillist,
  setEmailList,
}) {
  unedited = unedited || emaillist;
  const { t } = useTranslation();
  const [resetKey, setResetKey] = useState("sdfasfsda");

  return (
    <Grid item sm={3} xs={12}>
      <Title>{t("emailLists.productLines")}</Title>
      {unedited &&
        unedited.EmailListProductLines &&
        unedited.EmailListProductLines.map((u, i) =>
          u && u.ProductLine ? (
            <MenuItem
              key={i}
              style={
                emaillist.EmailListProductLines.find(
                  (uu) => uu.ProductLine.brandName === u.ProductLine.brandName
                )
                  ? {}
                  : { textDecoration: "line-through" }
              }
            >
              {u.ProductLine.brandName}
              <IconButton
                style={{ position: "absolute", right: 0 }}
                onClick={() => {
                  setEmailList({
                    ...emaillist,
                    EmailListProductLines:
                      emaillist.EmailListProductLines.filter(
                        (f) => f.ProductLine?.id !== u.ProductLine?.id
                      ),
                  });
                }}
              >
                <Clear />
              </IconButton>
            </MenuItem>
          ) : null
        )}
      {emaillist &&
        unedited &&
        emaillist.EmailListProductLines &&
        emaillist.EmailListProductLines.filter(
          (u) =>
            !unedited.EmailListProductLines.find(
              (f) => u.ProductLine?.id === f.ProductLine?.id
            )
        ).map((u, i) => (
          <MenuItem
            key={i}
            style={{
              backgroundColor: "rgb(177 255 184)",
            }}
          >
            {u.ProductLine.brandName}
            <IconButton
              style={{ position: "absolute", right: 0 }}
              onClick={() => {
                setEmailList({
                  ...emaillist,
                  EmailListProductLines: emaillist.EmailListProductLines.filter(
                    (f) => f.ProductLine.id !== u.ProductLine.id
                  ),
                });
              }}
            >
              <Clear />
            </IconButton>
          </MenuItem>
        ))}
      <ProductLineSearchAutocomplete
        style={{ marginTop: 5 }}
        key={resetKey}
        label={t("emailLists.searchProductLine")}
        onChange={(e, v) => {
          if (!v || !v.simplifiedName) {
            return;
          }
          setEmailList({
            ...emaillist,
            EmailListProductLines: [
              ...(emaillist.EmailListProductLines || []),
              { ProductLine: v },
            ],
          });
          setResetKey(new Date().toISOString());
        }}
      />
    </Grid>
  );
}
