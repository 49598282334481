import { CircularProgress, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import BadgeLink from "../../Components/BadgeLink";
import SortableTable from "../../Components/SortableTable";
import Title from "../../Components/Title";
import {
  ECABIN,
  formatTimeWithTZOffset,
  getToken,
  requestErrorHandler,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { useStyles } from "../../Utils/Styles";

const server = EnvSettings.server;

export default function SoftwareDetails() {
  const classes = useStyles();
  const [softwarePackages, setSoftwarePackages] = useState();
  const [latestVersion, setLatestVersion] = useState();
  const [crashlogs, setCrashlogs] = useState();
  const [crashlogStats, setCrashlogStats] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const fixedHeightPaper = clsx(classes.paper, classes.dashboardPaper);

  const formatSWData = (data) => {
    var arr = [];
    if (data && Array.isArray(data)) {
      data.forEach((d) => {
        var obj = {
          version: d.filename.replace(".zip", "").split("").join("."),
          date: formatTimeWithTZOffset(d.date, ECABIN).substring(0, 10),
          count: 0,
          percentage: 0,
        };
        arr.push(obj);
      });
    }
    return arr;
  };

  const formatCrashlogStats = (data) => {
    var arr = [];
    if (data && Array.isArray(data)) {
      data.forEach((d) => {
        var found = false;
        var obj = {
          version: d.version ? d.version : "0.0.0",
          count: 1,
        };
        arr.forEach((o, i) => {
          if (o && o.version && d.version === o.version) {
            found = true;
            arr[i] = { ...o, count: o.count + 1 };
          } else if (o && o.version === "0.0.0" && !d.version) {
            found = true;
            arr[i] = { ...o, count: o.count + 1 };
          }
        });
        if (!found) {
          arr.push(obj);
        }
      });
    }
    return arr;
  };

  const formatCrashlogs = (data) => {
    var arr = [];
    if (data && Array.isArray(data)) {
      data.forEach((d) => {
        var obj = {
          id: d.id,
          date:
            d.type === 1
              ? d.date
              : formatTimeWithTZOffset(d.date, ECABIN).substring(0, 10),
          serialNumber: d.Device.serialNumber,
          version: d.version,
          type: d.type,
          reason: d.reason,
        };
        arr.push(obj);
      });
    }
    return arr;
  };

  const getSoftwarePackages = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token: getToken() }),
    };
    fetch(server + "/get_software_packages", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          getDevices(formatSWData(result.packages));
        }
      });
  };

  const getDevices = (packages) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        requireLCS: false,
        search_value: "EC2",
      }),
    };
    fetch(server + "/get_admin_devices", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          if (result.devices) {
            var arr = packages;
            result.devices.forEach((d) => {
              var i = packages.findIndex((s) => s.version === d.version);
              if (i === -1) {
                return;
              }
              var s = arr[i];
              s.count = s.count + 1;
              s.percentage = s.count / result.devices.length;
              arr[i] = s;
            });
            setSoftwarePackages(arr);
          }
        }
      });
  };

  const getCrashlogs = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        // limit: 5,
      }),
    };
    fetch(server + "/get_crashlogs", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setCrashlogs(formatCrashlogs(result.crashlogs));
          setCrashlogStats(formatCrashlogStats(result.crashlogs));
        }
      });
  };

  const getLatestVersion = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token: getToken() }),
    };
    fetch(server + "/get_latest_software_version", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setLatestVersion(result.data);
        }
      });
  };

  useEffect(getSoftwarePackages, []);
  useEffect(getCrashlogs, []);
  useEffect(getLatestVersion, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid item xs={1}>
          <BadgeLink
            href={"/cpanel/software/releases"}
            title={t("software.releases")}
          />
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Paper className={fixedHeightPaper}>
          <Title>{t("software.releases")}</Title>
          <Grid container>
            {softwarePackages ? (
              <SortableTable
                headCells={[
                  {
                    id: "version",
                    numeric: false,
                    disablePadding: true,
                    label: t("version"),
                  },
                  {
                    id: "date",
                    numeric: false,
                    disablePadding: true,
                    label: t("releaseDate"),
                  },
                  {
                    id: "count",
                    numeric: false,
                    disablePadding: true,
                    label: "Active installs",
                  },
                  {
                    id: "percentage",
                    numeric: false,
                    disablePadding: true,
                    label: "%",
                  },
                ]}
                defaultSortRow={"date"}
                handleRowClick={() => {}}
                tableData={softwarePackages}
                rowsPerPage={5}
                disablePagination={true}
                tableSize={"small"}
                specialDataFormatting={[
                  {
                    id: "percentage",
                    format: (d) => (d * 100).toFixed(2),
                  },
                ]}
              />
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <Paper className={fixedHeightPaper}>
          <Title>{t("controlPanel.totalCrashCount")}</Title>
          <Grid container>
            {crashlogStats ? (
              <SortableTable
                headCells={[
                  {
                    id: "version",
                    numeric: false,
                    disablePadding: true,
                    label: t("version"),
                  },
                  {
                    id: "count",
                    numeric: false,
                    disablePadding: true,
                    label: t("count"),
                  },
                ]}
                defaultSortRow={"version"}
                handleRowClick={() => {}}
                tableData={crashlogStats}
                rowsPerPage={5}
                disablePagination={true}
                tableSize={"small"}
              />
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={5}>
        <Paper className={fixedHeightPaper}>
          <Title>{t("controlPanel.latestCrashReports")}</Title>
          {crashlogs ? (
            <SortableTable
              headCells={[
                {
                  id: "date",
                  numeric: false,
                  disablePadding: true,
                  label: t("date"),
                },
                {
                  id: "serialNumber",
                  numeric: false,
                  disablePadding: true,
                  label: t("serialNumber"),
                },
                {
                  id: "version",
                  numeric: false,
                  disablePadding: true,
                  label: t("version"),
                },
                {
                  id: "type",
                  numeric: false,
                  disablePadding: true,
                  label: t("type"),
                },
                {
                  id: "reason",
                  numeric: false,
                  disablePadding: true,
                  label: t("reason"),
                },
              ]}
              defaultSortRow={"date"}
              handleRowClick={(data) => {
                history.push("/cpanel/crashlogs/" + data.id);
              }}
              handleScrollClick={(event, id) => {
                if (window.location.href.includes("cpanel")) {
                  window.open("/cpanel/crashlogs/" + id.id, "_blank");
                }
              }}
              tableData={crashlogs}
              rowsPerPage={5}
              disablePagination={true}
              tableSize={"small"}
            />
          ) : (
            <CircularProgress />
          )}
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={fixedHeightPaper}>
          <Title>Latest version</Title>
          <Typography style={{ whiteSpace: "pre-wrap" }}>
            {latestVersion}
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}
