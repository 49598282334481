import {
  Breadcrumbs,
  Button,
  IconButton,
  Link,
  TextField,
  Typography,
  withWidth,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import BusinessIcon from "@material-ui/icons/Business";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import UserIcon from "@material-ui/icons/Person";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ContactSearchAutocomplete from "../../../Components/Contacts/ContactSearchAutocomplete";
import DeviceStatusBadge from "../../../Components/DeviceStatusBadge";
import SortableTable from "../../../Components/SortableTable";
import Title from "../../../Components/Title";

import CountrySearch from "../../../Components/CountrySearch";
import CountryFlag from "../../../Components/CountrySearch/CountryFlag";
import {
  ECABIN,
  checkAdmin,
  formatTimeWithTZOffset,
  getLatestLifeCycleState,
  getToken,
  requestErrorHandler,
} from "../../../Utils/Common";
import EnvSettings from "../../../Utils/EnvSettings";
import { useStyles } from "../../../Utils/Styles";
const server = EnvSettings.server;

function History({ data, width }) {
  const history = useHistory();
  const [searchList, setSearchList] = useState();
  const { t } = useTranslation();

  const createElements = (contact) => {
    var ar = [];
    contact.LifeCycleStates.forEach((lcs, i) => {
      if (!ar || !ar.find((o) => lcs.Device.serialNumber === o.serialNumber)) {
        var obj = {
          createdAt: formatTimeWithTZOffset(lcs.createdAt, ECABIN),
          serialNumber: lcs.Device.serialNumber,
          user:
            lcs.Device.LifeCycleStates.find((o) => o.id === lcs.id).User
              ?.fullName ?? "",
          withClient:
            getLatestLifeCycleState(lcs.Device.LifeCycleStates).updatedAt ===
            lcs.updatedAt
              ? t("yes")
              : t("returned"),
          DeviceId: lcs.Device.id,
        };
        ar.push(obj);
      }
    });
    setSearchList(ar);
  };

  useEffect(() => {
    if (data) {
      createElements(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return width === "xs" && searchList && searchList.length ? (
    searchList
      .sort((a, b) =>
        a.createdAt > b.createdAt ? -1 : b.createdAt > a.createdAt ? 1 : 0
      )
      .map((val, i) => (
        <DeviceStatusBadge data={val} onClick={() => {}} key={i} />
      ))
  ) : searchList && searchList.length ? (
    <SortableTable
      headCells={[
        {
          id: "serialNumber",
          numeric: false,
          disablePadding: false,
          label: t("serialNumber"),
        },
        {
          id: "createdAt",
          numeric: false,
          disablePadding: false,
          label: t("date"),
        },
        {
          id: "user",
          numeric: false,
          disablePadding: false,
          label: t("representativeName"),
        },
        {
          id: "withClient",
          numeric: false,
          disablePadding: false,
          label: t("dispatchedDevices.withClient"),
        },
      ]}
      disablePagination={true}
      defaultSortRow={"serialNumber"}
      handleRowClick={(e, v) => {
        history.push(
          (checkAdmin() ? "/cpanel" : "") + "/devices/" + e.DeviceId
        );
      }}
      // handleScrollClick: showDeviceNewTab,
      tableData={searchList}
      rowsPerPage={200}
    />
  ) : searchList ? (
    <Typography>{t("dispatchedDevices.noDispatchedDevices")}</Typography>
  ) : null;
}

function Contact(props) {
  const { type, id } = props.computedMatch.params;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [newCompany, setNewCompany] = useState();
  const [newLocation, setNewLocation] = useState();
  const [newContact, setNewContact] = useState();
  const [editLocation, setEditLocation] = useState(false);
  const [newParent, setNewParent] = useState();
  const [addParent, setAddParent] = useState(false);
  const [addContacts, setAddContacts] = useState(false);
  const [newClient, setNewClient] = useState();
  const [editAdditionalInfo, setEditAdditionalInfo] = useState(false);
  const [editCompanyName, setEditCompanyName] = useState(false);
  const [newAdditionalInfo, setNewAdditionalInfo] = useState();
  const history = useHistory();

  const additionalInfo = [
    { name: "companyAddress", multiline: true },
    { name: "companyVatId" },
  ];

  const getContact = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        contactType: type,
        contactId: id,
      }),
    };
    fetch(server + "/get_contact", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setContact(result.contact);
        }
      });
  };

  const submitEditCompanyName = () => {
    if (type !== "company" || newCompany === "" || !id) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        contactData: {
          companyName: newCompany,
          companyId: id,
        },
      }),
    };
    fetch(server + "/edit_contact", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          window.location.reload();
        }
      });
  };

  const editContact = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        contactData: {
          ...(type === "company"
            ? { companyName: contact.name }
            : { contactId: id }),
          ...(newLocation ? { location: newLocation } : {}),
          ...(newCompany ? { companyName: newCompany } : {}),
          ...(newContact ? { contactName: newContact } : {}),
          ...(newParent ? { parentCompany: newParent } : {}),
          ...(newAdditionalInfo ? { additionalInfo: newAdditionalInfo } : {}),
        },
      }),
    };
    fetch(server + "/edit_contact", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          if (result.company) {
            history.push("/contacts/company/" + result.company.id);
          }
          window.location.reload();
        }
      });
  };

  const editClient = () => {
    if (!newClient) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        contactData: {
          ...newClient,
          parentCompany: { companyId: id },
        },
      }),
    };
    fetch(server + "/edit_contact", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          window.location.reload();
        }
      });
  };

  useEffect(() => {
    if (type && id) {
      getContact();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, id]);

  return contact ? (
    <Paper className={classes.paper}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              href={checkAdmin() ? "/cpanel/contacts" : "/contacts"}
            >
              {t("mainSideBar.contacts")}
            </Link>
            <Typography color="textPrimary">{contact.name}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <Title
            style={editCompanyName ? { textDecoration: "line-through" } : {}}
          >
            {contact.name}{" "}
            {type === "company" ? (
              <IconButton
                onClick={() => {
                  if (editCompanyName) {
                    setNewCompany();
                  }
                  setEditCompanyName(!editCompanyName);
                }}
              >
                {!editCompanyName ? <EditIcon /> : <CancelIcon />}
              </IconButton>
            ) : null}
          </Title>
          {editCompanyName ? (
            <TextField
              variant="outlined"
              fullWidth
              label={t("contactInfo.companyName")}
              inputProps={{ maxLength: 255 }}
              defaultValue={contact.name}
              onChange={(e) => {
                setNewCompany(e.target.value);
              }}
            />
          ) : null}
          {editCompanyName && newCompany ? (
            <Button
              variant="contained"
              color="primary"
              onClick={submitEditCompanyName}
            >
              {t("submit")}
            </Button>
          ) : null}
        </Grid>
        <Grid item sm={3} xs={12}>
          {type === "company" ? (
            <>
              <Title>
                Info{" "}
                <IconButton
                  onClick={() => {
                    if (editAdditionalInfo) {
                      setNewAdditionalInfo();
                    }
                    setEditAdditionalInfo(!editAdditionalInfo);
                  }}
                >
                  {!editAdditionalInfo ? <EditIcon /> : <CancelIcon />}
                </IconButton>
              </Title>
              {additionalInfo.map((m, i) => (
                <TextField
                  variant="outlined"
                  key={i}
                  value={
                    newAdditionalInfo?.[m.name] ||
                    contact.additionalInfo?.[m.name]
                  }
                  fullWidth
                  label={t("contactInfo." + m.name)}
                  multiline={m.multiline}
                  rows={m.multiline ? 6 : 1}
                  onChange={(e) => {
                    setNewAdditionalInfo({
                      ...(newAdditionalInfo ? newAdditionalInfo : {}),
                      [m.name]: e.target.value,
                    });
                  }}
                  disabled={!editAdditionalInfo}
                  style={{ marginBottom: 5 }}
                />
              ))}
              {editAdditionalInfo && newAdditionalInfo ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={editContact}
                >
                  {t("submit")}
                </Button>
              ) : null}{" "}
            </>
          ) : null}
        </Grid>
        <Grid item sm={3} xs={12}>
          {contact.Contacts && contact.location ? (
            <>
              <Title>{t("contactInfo.location")}</Title>
              <Typography>
                <CountryFlag country_name={contact.location} />
                {contact.location || ""}
                <IconButton
                  onClick={() => {
                    if (editLocation) {
                      setNewLocation();
                    }
                    setEditLocation(!editLocation);
                  }}
                >
                  {!editLocation ? <EditIcon /> : <CancelIcon />}
                </IconButton>
              </Typography>
            </>
          ) : null}
          {(!contact.location && contact.Contacts) || editLocation ? (
            <CountrySearch
              onChange={(e, v) => {
                if (v) {
                  setNewLocation(v.country);
                } else {
                  setNewLocation();
                }
              }}
            />
          ) : null}
          {newLocation ? (
            <Button variant="contained" color="primary" onClick={editContact}>
              {t("submit")}
            </Button>
          ) : null}
        </Grid>
        {contact.Contacts ? (
          <>
            <Grid item sm={3} xs={12} spacing={1}>
              <Title>{t("contactInfo.contacts")}</Title>
              {contact.Contacts.map((c, i) => (
                <Grid
                  item
                  container
                  alignContent="center"
                  key={i}
                  style={{ marginBottom: 5 }}
                >
                  <UserIcon />
                  <Typography>{c.name}</Typography>
                </Grid>
              ))}
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label={t("contactInfo.addContact")}
                  size="small"
                  onChange={(e) => {
                    setNewContact(e.target.value);
                  }}
                ></TextField>
                {newContact ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={editContact}
                  >
                    {t("submit")}
                  </Button>
                ) : null}
              </Grid>
            </Grid>
            <Grid item sm={3} xs={12} spacing={1}>
              {contact.Parent || addParent ? (
                <Title>{t("contactInfo.parentCompany")}</Title>
              ) : null}
              {contact.Parent ? (
                <Grid item xs={12} container alignItems="center">
                  <BusinessIcon style={{ marginRight: 20 }} />
                  <Link
                    href={
                      (checkAdmin() ? "/cpanel" : "") +
                      "/contacts/company/" +
                      contact.Parent.id
                    }
                    style={{ fontSize: 20 }}
                  >
                    {contact.Parent.name}
                  </Link>
                </Grid>
              ) : addParent ? (
                <>
                  <Grid item xs={12}>
                    <Typography>{t("contactInfo.addParent")}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ContactSearchAutocomplete
                      onlyCompanies={true}
                      noDescriptionText={true}
                      onChange={(e, v) => {
                        if (v) {
                          setNewParent(v);
                        } else {
                          setNewParent();
                        }
                      }}
                    />
                    {newParent ? (
                      <>
                        <Typography>{newParent.companyName}</Typography>
                        <Button variant="outlined" onClick={editContact}>
                          {t("submit")}
                        </Button>
                      </>
                    ) : null}
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setAddParent(true);
                    }}
                  >
                    {t("contactInfo.contactThroughReseller")}
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid item sm={3} xs={12} container spacing={1}>
              {(contact.EndCustomer && contact.EndCustomer.length) ||
              addContacts ? (
                <>
                  <Grid item xs={12}>
                    <Title>{t("contactInfo.resellerClients")}</Title>
                  </Grid>
                  <Grid item xs={12} container>
                    {contact.EndCustomer
                      ? contact.EndCustomer.map((ec, i) => (
                          <Grid
                            key={i}
                            item
                            xs={12}
                            container
                            alignItems="center"
                          >
                            <BusinessIcon style={{ marginRight: 20 }} />
                            <Link
                              style={{ fontSize: 20 }}
                              href={
                                (checkAdmin() ? "/cpanel" : "") +
                                "/contacts/company/" +
                                ec.id
                              }
                            >
                              {ec.name}
                            </Link>
                          </Grid>
                        ))
                      : null}
                  </Grid>

                  <Grid item xs={12}>
                    <Typography>
                      {t("contactInfo.addResellerClient")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ContactSearchAutocomplete
                      onlyCompanies={true}
                      noDescriptionText={true}
                      onChange={(e, v) => {
                        if (v) {
                          setNewClient(v);
                        } else {
                          setNewClient();
                        }
                      }}
                    />
                  </Grid>
                  {newClient ? (
                    <Button variant="outlined" onClick={editClient}>
                      {t("submit")}
                    </Button>
                  ) : null}
                </>
              ) : (
                <Button
                  onClick={() => {
                    setAddContacts(true);
                  }}
                  variant="outlined"
                >
                  {t("contactInfo.resellerCompany")}
                </Button>
              )}
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label={t("contactInfo.addCompany")}
              size="small"
              onChange={(e) => {
                setNewCompany(e.target.value);
                if (!e.target.value) {
                  setNewLocation();
                }
              }}
            />
            {newCompany ? (
              <TextField
                variant="outlined"
                label={t("contactInfo.addLocation")}
                size="small"
                onChange={(e) => {
                  setNewLocation(e.target.value);
                }}
              />
            ) : null}
          </Grid>
        )}
        <Grid item xs={12}>
          <Title>{t("devices.header")}</Title>
          <History data={contact} width={props.width} />
        </Grid>
      </Grid>
    </Paper>
  ) : null;
}

export default withWidth()(Contact);
