import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import SortableTable from "../../../Components/SortableTable";
import Title from "../../../Components/Title";
import {
  ECABIN,
  findValueInText,
  formatClientInfoString,
  formatTimeWithTZOffset,
  getToken,
  lifeCycleStates,
  readClientDataFromLCS,
  requestErrorHandler,
} from "../../../Utils/Common";
import EnvSettings from "../../../Utils/EnvSettings";
import { useStyles } from "../../../Utils/Styles";

import { Dialog, DialogContent, Link, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import DateSelect from "../../../Components/DateSelect";
import DialogAppBar from "../../../Components/DialogAppBar";
import { checkAdmin, deviceProfiles } from "../../../Utils/Common";
import Checklist from "../../../Views/DeviceDetails/Checklist";
import LifeCycleState from "../../../Views/DeviceDetails/LifeCycleState";
import DeviceDataForm from "../../../Views/Production/DeviceDataForm";
import ProductionPhotos from "../../../Views/Production/ProductionPhotos";
const server = EnvSettings.server;

function SelectedDeviceDialog({
  selectedDevice,
  setSelectedDevice,
  searchList,
  setSearchList,
}) {
  const { t } = useTranslation();
  return selectedDevice ? (
    <Dialog
      open={!!selectedDevice}
      onClose={() => {
        setSelectedDevice(undefined);
      }}
      fullScreen
    >
      <DialogContent>
        <DialogAppBar
          header={
            <>
              {" "}
              {selectedDevice.serialNumber}{" "}
              <Link
                style={{ color: "white" }}
                href={
                  (checkAdmin() ? "/cpanel" : "") +
                  "/devices/" +
                  selectedDevice.id
                }
              >
                {"- " + t("viewDevicePage")}
              </Link>
            </>
          }
          onClose={() => {
            setSelectedDevice(undefined);
          }}
        />
        <Grid
          container
          spacing={3}
          style={{
            marginTop: 60,
            marginBottom: 20,
            paddingBottom: 30,
            position: "relative",
          }}
        >
          <Grid item xs={12}>
            <DeviceDataForm
              deviceData={selectedDevice}
              updateLocalDevice={(lcs) => {
                var a = searchList.findIndex(
                  (obj) => obj.id === selectedDevice.id
                );
                var arr = [...searchList];
                setSearchList();
                arr[a] = { ...lcs };
                setSearchList([...arr]);
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ borderTop: "1px solid #c5c5c5", marginTop: 12 }}
          >
            <Checklist device_id={selectedDevice.id} />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ borderTop: "1px solid #c5c5c5", marginTop: 12 }}
          >
            <ProductionPhotos device_id={selectedDevice.id} />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ borderTop: "1px solid #c5c5c5", marginTop: 12 }}
          >
            <LifeCycleState
              deviceData={selectedDevice}
              updateLocalDevice={(lcs) => {
                var a = searchList.findIndex(
                  (obj) => obj.id === selectedDevice.id
                );
                var arr = [...searchList];
                arr[a] = { ...selectedDevice, LifeCycleStates: lcs };
                setSearchList(arr);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  ) : null;
}

const compareDates = (data, date) => {
  if (!data || !date) {
    return true;
  }
  var start =
    date && date.startDate && date.startDate !== ""
      ? Date.parse(date.startDate)
      : undefined;
  var end =
    date && date.endDate && date.endDate !== ""
      ? Date.parse(date.endDate) + 1000 * (60 * 60 * 23 + 60 * 59) // 23:59
      : undefined;
  var d = Date.parse(data);
  if (start && end) {
    return d >= start && d <= end;
  }
  if (start) {
    return d >= start;
  }
  if (end) {
    return d <= end;
  }
  return true;
};

const checkUnique = (value, index, arr) => {
  return !arr.find((d) => d.Device.id === value.Device.id && d.id > value.id);
};

export default function Production(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [stateList, setStateList] = useState();
  const [searchList, setSearchList] = useState();
  const [searchValue, setSearchValue] = useState();
  const [selectedDevice, setSelectedDevice] = useState();
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [selectedTransfer, setSelectedTransfer] = useState();
  const [onlyLastState, setOnlyLastState] = useState();
  const [date, setDate] = useState();
  const [limit, setLimit] = useState(500);
  const dispatch = useDispatch();
  const [filterStates, setFilterStates] = useState(
    lifeCycleStates.map((m) => m.value)
  );

  useEffect(() => {
    const getTransfers = () => {
      setLoading(true);
      setSearchList();
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: getToken(),
          user: "Admin",
          limit: limit,
        }),
      };
      fetch(server + "/get_life_cycle_states", requestOptions)
        .then((res) => res.json())
        .then((result) => {
          requestErrorHandler(result, dispatch);
          if (!result.error) {
            setStateList(result.lcs);
            // setSearchList(result.lcs);
          }
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    };
    getTransfers();
  }, [dispatch, t, limit]);

  const filterResults = () => {
    setSearchList(
      stateList &&
        stateList.filter((val, index) => {
          return (
            (onlyLastState ? checkUnique(val, index, searchList) : true) &&
            filterStates.find((o) => o === val.state) &&
            (date ? compareDates(val.createdAt, date) : true) &&
            (findValueInText(val.state, searchValue) ||
              findValueInText(val.clientInfo, searchValue) ||
              findValueInText(val.location, searchValue) ||
              findValueInText(val.Notes, searchValue, "text") ||
              findValueInText(val.Device.serialNumber, searchValue))
          );
        })
    );
  };

  useEffect(filterResults, [
    searchValue,
    stateList,
    date,
    filterStates,
    onlyLastState,
  ]);

  return (
    <Grid container spacing={3}>
      <SelectedDeviceDialog
        selectedDevice={selectedDevice}
        setSelectedDevice={setSelectedDevice}
        searchList={searchList}
        setSearchList={setSearchList}
      />
      <Grid item xs={12}>
        <Paper className={classes.paper} style={{ overflow: "hidden" }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item container spacing={3} xs={12}>
              <Grid item xs={12}>
                <Title>{t("transferList.header")}</Title>
              </Grid>
              <Grid item container xs={12}>
                <Grid
                  item
                  style={{
                    border: "1px solid lightgrey",
                    borderRadius: 5,
                    padding: 5,
                    minWidth: 295,
                  }}
                  xs={12}
                  container
                >
                  <Grid item xs={12}>
                    <Typography style={{ color: "grey" }}>
                      {t("quickSelection")}
                    </Typography>
                  </Grid>
                  {deviceProfiles.map((m, i) => (
                    <Grid item style={{ margin: 5 }} key={i}>
                      <Button
                        onClick={() => {
                          setSearchValue(m.prefix);
                          //   getDevices(m.prefix);
                        }}
                        variant="outlined"
                      >
                        {m.name}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value.toLowerCase());
                  }}
                  InputLabelProps={{ shrink: !!searchValue ? true : undefined }}
                  fullWidth
                  label={t("search")}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>{t("orders.paymentStatus")}</Typography>
                {lifeCycleStates.map((o) => (
                  <FormControlLabel
                    key={o.label}
                    control={
                      <Checkbox
                        color="primary"
                        onChange={(e, v) => {
                          if (v) {
                            setFilterStates([...filterStates, o.value]);
                            return;
                          }
                          setFilterStates([
                            ...filterStates.filter((f) => f !== o.value),
                          ]);
                        }}
                        defaultChecked={
                          !!filterStates.find((f) => f === o.value)
                        }
                      />
                    }
                    label={o.label}
                  />
                ))}
              </Grid>
              <Grid item xs={12}>
                <DateSelect
                  setLimit={(e) => {
                    if (!e) {
                      return;
                    }
                    setLimit(Number(e));
                  }}
                  setDate={setDate}
                  date={date}
                  hideTime={true}
                  noPaper={true}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography color="primary">
                {searchList ? t("transferList.transfers") : ""}
              </Typography>
              <Typography>{searchList?.length}</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    onChange={(e, v) => {
                      setOnlyLastState(!onlyLastState);
                    }}
                    defaultChecked={false}
                  />
                }
                label={t("transferList.onlyLastState")}
              ></FormControlLabel>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Title>{t("transferList.transfers")}</Title>
          {searchList ? (
            <SortableTable
              header={t("")}
              downloadButton={true}
              headCells={[
                {
                  id: "createdAt",
                  numeric: false,
                  disablePadding: false,
                  label: t("date"),
                },
                {
                  id: "state",
                  numeric: false,
                  disablePadding: false,
                  label: t("transferState"),
                },
                {
                  id: "location",
                  numeric: false,
                  disablePadding: false,
                  label: t("newLocation"),
                },
                {
                  id: "User",
                  numeric: false,
                  disablePadding: false,
                  label: t("representativeName"),
                },
                {
                  id: "Device.serialNumber",
                  numeric: false,
                  disablePadding: false,
                  label: t("serialNumber"),
                },
                {
                  id: "clientInfo",
                  numeric: false,
                  disablePadding: false,
                  label: t("clientInfo"),
                },
                {
                  id: "Notes",
                  numeric: false,
                  disablePadding: false,
                  label: t("sessionDetails.notes"),
                },
              ]}
              defaultSortRow={"createdAt"}
              handleRowClick={(e, v) => {
                setSelectedTransfer(e);
              }}
              // handleScrollClick = {showDeviceNewTab,
              tableData={searchList}
              rowsPerPage={rowsPerPage}
              specialDataFormatting={[
                {
                  id: "createdAt",
                  format: (d) => formatTimeWithTZOffset(d, ECABIN),
                },
                {
                  id: "User",
                  format: (d) => d?.fullName,
                },
                {
                  id: "clientInfo",
                  format: (d, dd, ddd) =>
                    formatClientInfoString(readClientDataFromLCS(ddd), [
                      "companyName",
                      "contactName",
                      "location",
                    ]),
                },
                {
                  id: "Notes",
                  format: (d) => d?.map((n) => n.text).join(" / "),
                },
              ]}
            />
          ) : loading ? (
            <CircularProgress />
          ) : undefined}
        </Paper>
      </Grid>
    </Grid>
  );
}
