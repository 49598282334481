import { Dialog, DialogContent, withWidth } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import DialogAppBar from "../../../Components/DialogAppBar";
import Title from "../../../Components/Title";
import { getToken, requestErrorHandler } from "../../../Utils/Common";
import EnvSettings from "../../../Utils/EnvSettings";
import OrderShipment from "./OrderShipment";
import OtherShipment from "./OtherShipment";
import ResupplyShipment from "./ResupplyShipment";
// import OrderLine from "./OrderLine";

/**
 * Delivery to
 * Items
 * External Ref
 * Order
 * Term
 * Shipping Company
 * Status
 * Pickup
 * ETA
 * Tracking number
 */

const server = EnvSettings.server;

function NewShipment({ width, open, handleExit, orderId, resupplyOrderId }) {
  const [showForm, setShowForm] = useState();
  const [shipmentData, setShipmentData] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const submitOrder = (file) => {
    if (!shipmentData) {
      return;
    }
    if (
      !shipmentData.genericDevices ||
      !shipmentData.genericDevices.length ||
      !shipmentData.genericDevices.reduce(
        (prev, curr) => Number(curr.count) + prev,
        0
      )
    ) {
      alert("No items selected!");
      return;
    }
    if (!shipmentData.number) {
      shipmentData.number = crypto.randomUUID();
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        shipment: shipmentData,
      }),
    };
    fetch(server + "/add_shipment", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          history.push("/cpanel/shipments");
          window.location.reload();
        }
      });
  };

  const resetForm = () => {
    setShipmentData();
    setShowForm();
    handleExit();
  };

  useEffect(() => {
    if (orderId) {
      setShowForm("order");
    }
    if (resupplyOrderId) {
      setShowForm("resupply");
    }
  }, []);

  return open ? (
    <Dialog open={!!open} fullScreen onClose={resetForm}>
      <DialogContent>
        <DialogAppBar header={t("shipments.newShipment")} onClose={resetForm} />
        <Grid
          container
          spacing={1}
          style={{
            marginTop: 60,
            marginBottom: 20,
            paddingBottom: 30,
            position: "relative",
          }}
        >
          {showForm && showForm === "order" ? (
            <OrderShipment
              shipmentData={shipmentData}
              setShipmentData={setShipmentData}
              orderId={orderId}
            />
          ) : showForm && showForm === "resupply" ? (
            <ResupplyShipment
              shipmentData={shipmentData}
              setShipmentData={setShipmentData}
              resupplyOrderId={resupplyOrderId}
            />
          ) : showForm && showForm === "transfer" ? (
            <OtherShipment
              shipmentData={shipmentData}
              setShipmentData={setShipmentData}
            />
          ) : (
            <Grid container spacing={3} item xs={4}>
              <Grid item xs={12}>
                <Title>{t("shipments.selectShipmentReason")}</Title>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={() => {
                    setShowForm("order");
                  }}
                >
                  {t("shipments.forOrder")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={() => {
                    setShowForm("resupply");
                  }}
                >
                  {t("shipments.forResupplyOrder")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={() => {
                    setShowForm("transfer");
                  }}
                >
                  {t("shipments.forOther")}
                </Button>
              </Grid>
            </Grid>
          )}

          <Grid item sm={6} xs={12} container spacing={1}>
            {showForm ? (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={submitOrder}
                >
                  {t("submit")}
                </Button>
              </Grid>
            ) : null}
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={resetForm}
              >
                {t("cancel")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  ) : null;
}

export default withWidth()(NewShipment);
