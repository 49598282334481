import Grid from "@material-ui/core/Grid";
import React from "react";
import { useTranslation } from "react-i18next";

import ShippingItemLine from "./ShippingItemLine";

export default function ShippingItemTable({
  listOfItems,
  shipmentData,
  setShipmentData,
  width,
  onlyPrint,
}) {
  const { t } = useTranslation();

  return listOfItems && shipmentData ? (
    <>
      <Grid
        container
        style={{
          marginTop: 10,
          borderTop: "1px solid #000",
          padding: 5,
        }}
      >
        <Grid item sm={1} xs={1}></Grid>
        <Grid item sm={3} xs={3} style={{ padding: 5 }}>
          SKU
        </Grid>
        <Grid item sm={3} xs={3} style={{ padding: 5 }}>
          {t("description")}
        </Grid>
        <Grid item sm={2} xs={3} style={{ padding: 5 }}>
          {t("shipments.numberOfSelectedItems")}
        </Grid>
        <Grid item sm={3} xs={1} style={{ padding: 5 }}>
          {shipmentData.Order || shipmentData.ResupplyOrder
            ? t("shipments.numberItemsInOrder")
            : null}
        </Grid>
      </Grid>

      {listOfItems.map((dev, i) => (
        <ShippingItemLine
          key={i}
          item={dev}
          shipmentData={shipmentData}
          setShipmentData={setShipmentData}
          width={width}
          onlyPrint={onlyPrint}
        />
      ))}
    </>
  ) : null;
}
